import React from 'react'

const InkHighlighterMoveIcon = ({
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M13.8 4H7V2h8.8l-2 2Zm-4 4H4V6h7.8l-2 2Zm-4 4H1v-2h6.8l-2 2Zm8.8 2L12 11.4l-5 5L9.6 19l5-5Zm-1.2-4 2.6 2.6 5-5L18.4 5l-5 5Zm-2-.7 5.3 5.4-5.7 5.7a2 2 0 0 1-2.8 0l-.7.6h-5l3.2-3.1-.1-.1a2 2 0 0 1 0-2.8l5.7-5.7Zm0 0L17 3.6a2 2 0 0 1 2.8 0l2.6 2.6a2 2 0 0 1 0 2.8l-5.7 5.7-5.4-5.4Z"
      />
    </svg>
  )
}

export default InkHighlighterMoveIcon

import {
  keepPreviousData,
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import api from '@/lib/axios'
import {
  AssetInsightDeliveryNote,
  CreateAssetInsightDeliveryNoteDto,
} from '@/types/delivery-note'
import { RequestParams } from '@/types/general'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dummyData: any[] = [
  {
    id: '61b3fbdb-d64f-43b6-8666-ae388bd11378',
    tar: {
      type: 'Contract',
      status: 'pending',
      tarNumber: 'TAR-0001',
      costCenter: '101.123',
      transferBy: {
        label: 'Syafruddin Batubara',
        value: '01fd202f-26f9-4c41-8410-cc012ac0ab3a',
        key: '01fd202f-26f9-4c41-8410-cc012ac0ab3a',
      },
      movementDate: '2024-04-01T17:00:00.000Z',
      assets: [
        {
          contract: {
            contractTitle: 'Title 1',
            id: '168d89df-6060-4f4d-923b-b63a68150ff1',
            location: 'Location A',
          },
          asset: {
            name: 'Asset A',
            id: '3862a700-7db8-4448-9a0a-41fedd4b25d9',
            number: 'Asset 1',
            serialNumber: 'Asset SN 1',
            description:
              'Lorem ipsum dolor sit amet consectetur adipiscing elit tristique himenaeos proin eleifend, erat sociis leo quam sollicitudin vivamus magnis blandit magna parturient, hendrerit duis pharetra fringilla vestibulum laoreet facilisis felis nisl volutpat.',
          },
          destinedLocation: 'Area 1',
          remarks:
            'Habitant libero augue curabitur risus vestibulum laoreet, semper tellus dis luctus habitasse duis dictumst, cursus ornare torquent rhoncus dapibus.',
        },
      ],
      destinedContract: 'Area 1',
    },
    tarStatus: 'tar need action',
    status: 'pending',
    type: 'asset',
    no: 'DN-001',
    date: '2024-04-01T17:00:00.000Z',
    customer: 'Affri',
    address: 'Jalan sijuk',
    carNumber: 'BN 8990 FO',
    driver: {
      name: 'Cio Pratama',
      id: 'eff31349-999a-45db-967f-8af9e2e54e6f',
    },
    sendBy: {
      name: 'Dwi Manyu Widianto',
      id: '05238e3a-4f53-4370-b16a-078e105478e9',
    },
    contract: {
      contractTitle: 'Title 1',
      id: '168d89df-6060-4f4d-923b-b63a68150ff1',
    },
    reffNo: 'REF_0001',
    telephone: '08128391283',
    fax: 'Fax A',
    contactPerson: 'Affri Affri',
    expedition: 'Expedition XYZ',
    transportationType: 'Jalur Darat',
    receiptBy: 'Nazla',
    items: [
      {
        data: {
          id: '3862a700-7db8-4448-9a0a-41fedd4b25d9',
          name: 'Asset A',
          serialNumber: 'Asset SN 1',
          description:
            'Lorem ipsum dolor sit amet consectetur adipiscing elit tristique himenaeos proin eleifend, erat sociis leo quam sollicitudin vivamus magnis blandit magna parturient, hendrerit duis pharetra fringilla vestibulum laoreet facilisis felis nisl volutpat.',
        } as never,
        boxNo: '001',
        remarks:
          'Integer dapibus enim commodo condimentum inceptos odio imperdiet magnis cursus sodales',
        qty: 1,
      },
      {
        data: {
          id: '3862a700-7db8-4448-9a0a-41fedd4b25d9',
          name: 'Asset A',
          serialNumber: 'Asset SN 1',
          description:
            'Lorem ipsum dolor sit amet consectetur adipiscing elit tristique himenaeos proin eleifend, erat sociis leo quam sollicitudin vivamus magnis blandit magna parturient, hendrerit duis pharetra fringilla vestibulum laoreet facilisis felis nisl volutpat.',
        } as never,
        boxNo: '002',
        remarks:
          ' Fermentum facilisis posuere enim justo accumsan varius lacus natoque, est lectus vel a mus mattis cum ut volutpat, eget sodales aliquet fringilla cubilia inceptos gravida. Lobortis praesent viverra leo nulla tempus commodo blandit class sed',
        qty: 1,
      },
    ],
  },
  {
    id: '8d4c3e8b-af3b-4e5f-a745-23fbf196467xxxe',
    type: 'tool-material',
    no: '0001',
    date: '2024-04-02T17:00:00.000Z',
    customer: 'Affri',
    address: 'Belitung',
    carNumber: '23213123',
    status: 'pending',
    driver: {
      name: 'Other',
      id: 'other',
    },
    sendBy: {
      name: 'Other',
      id: 'other',
    },
    contract: {
      contractTitle: 'Title 1',
      id: '168d89df-6060-4f4d-923b-b63a68150ff1',
    } as never,
    reffNo: '213213',
    telephone: '123213',
    fax: 'asdsd',
    contactPerson: 'Afff',
    expedition: 'Expedition A',
    transportationType: 'Type A ',
    receiptBy: 'Deo',
    items: [
      {
        data: {
          id: 'bb86c96f-ffff-46e1-b224-c7f38eceb6e2',
          partId: '033-8734 - Material',
          description: 'ELBOW-FLARED, CATERPILLAR P/N: 033-8734',
          uom: 'EA',
        },
        qty: 10,
        boxNo: 'A',
        remarks: 'asdasdsadasd',
      } as never,
      {
        data: {
          id: 'd33d9e6a-eaec-4d80-8595-36ba960aac2c',
          partId: '043-0029-00 - Material',
          description: 'FUSE HVC 5000V DELAY',
          uom: 'EA',
        },
        qty: 20,
        boxNo: 'V',
        remarks: '123',
      },
    ],
    otherDriverName: 'Susi',
    otherSendByName: 'Hamdani',
  },
  {
    id: '8d4c3e8b-af3b-4e5f-a745-23fbf196467e',
    type: 'tool-material',
    no: '0003',
    date: '2024-04-02T17:00:00.000Z',
    customer: 'Affri',
    address: 'Belitung',
    carNumber: '23213123',
    status: 'approved',
    driver: {
      name: 'Other',
      id: 'other',
    },
    sendBy: {
      name: 'Other',
      id: 'other',
    },
    contract: {
      contractTitle: 'Title 1',
      id: '168d89df-6060-4f4d-923b-b63a68150ff1',
    } as never,
    reffNo: '213213',
    telephone: '123213',
    fax: 'asdsd',
    contactPerson: 'Afff',
    expedition: 'Expedition A',
    transportationType: 'Type A ',
    receiptBy: 'Deo',
    items: [
      {
        data: {
          id: 'bb86c96f-ffff-46e1-b224-c7f38eceb6e2',
          partId: '033-8734 - Material',
          description: 'ELBOW-FLARED, CATERPILLAR P/N: 033-8734',
          uom: 'EA',
        },
        qty: 10,
        boxNo: 'A',
        remarks: 'asdasdsadasd',
      },
      {
        data: {
          id: 'd33d9e6a-eaec-4d80-8595-36ba960aac2c',
          partId: '043-0029-00 - Material',
          description: 'FUSE HVC 5000V DELAY',
          uom: 'EA',
        },
        qty: 20,
        boxNo: 'V',
        remarks: '123',
      },
    ],
    otherDriverName: 'Susi',
    otherSendByName: 'Hamdani',
  },
]

// const fetchDeliveryNotes = async (filters: RequestParams) => {
//   const response = await api.get('/asset-insight/delivery-note', {
//     params: filters,
//   })
//   return response.data as APIResponse<AssetInsightDeliveryNote[]>
// }
// const fetchDeliveryNote = async (id: string | undefined) => {
//   const response = await api.get(`/asset-insight/delivery-note/${id}`)
//   return response.data as APIResponseWithoutTotal<AssetInsightDeliveryNote>
// }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fetchDeliveryNotes = async (_filters: RequestParams) => {
  return Promise.resolve({ data: dummyData, total: dummyData.length })
}
const fetchDeliveryNote = async (id: string | undefined) => {
  return Promise.resolve(dummyData.find((d) => d.id === id)!)
}

const createDeliveryNote = async (
  payload: CreateAssetInsightDeliveryNoteDto
) => {
  const response = await api.post('/asset-insight/delivery-note', payload)
  return response.data as AssetInsightDeliveryNote
}
const updateDeliveryNote = async (
  id: string | undefined,
  payload: CreateAssetInsightDeliveryNoteDto
) => {
  const response = await api.put(`/asset-insight/delivery-note/${id}`, payload)
  return response.data as AssetInsightDeliveryNote
}
const deleteDeliveryNote = async (id: string | string[]) => {
  const ids = typeof id === 'string' ? [id] : id
  return api.post(`/asset-insight/delivery-note/multiple/force-delete`, { ids })
}

const queires = {
  all: () => ['write offs'],
  lists: () => [...queires.all(), 'list'],
  list: (filters: RequestParams) =>
    queryOptions({
      queryKey: [...queires.lists(), filters],
      queryFn: () => fetchDeliveryNotes(filters),
      placeholderData: keepPreviousData,
    }),
  details: () => [...queires.all(), 'detail'],
  detail: (id: string | undefined) =>
    queryOptions({
      queryKey: [...queires.details(), id],
      queryFn: () => fetchDeliveryNote(id),
      enabled: Boolean(id),
      staleTime: 5000,
    }),
}

export const useDeliveryNotes = (filter: RequestParams) => {
  return useQuery(queires.list(filter))
}
export const useDeliveryNote = (id: string | undefined) => {
  return useQuery(queires.detail(id))
}
export const useCreateDeliveryNote = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: CreateAssetInsightDeliveryNoteDto) =>
      createDeliveryNote(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queires.lists(),
      })
    },
  })
}
export const useUpdateDeliveryNote = (id: string | undefined) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: CreateAssetInsightDeliveryNoteDto) =>
      updateDeliveryNote(id, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queires.lists(),
      })
    },
  })
}
export const useDeleteDeliveryNote = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string | string[]) => deleteDeliveryNote(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queires.lists(),
      })
    },
  })
}

import ReactAuthKitProvider from 'react-auth-kit'

import createStore from 'react-auth-kit/createStore'

const store = createStore({
  authName: '_auth',
  authType: 'cookie',
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === 'https:',
})

function AuthProvider({ children }: { children: React.ReactNode }) {
  return <ReactAuthKitProvider store={store}>{children}</ReactAuthKitProvider>
}

export default AuthProvider

import React from 'react'

const NotificationIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 22a2 2 0 0 0 2-2h-4c0 1.1.9 2 2 2Zm6-6v-5c0-3-1.6-5.6-4.5-6.3V4a1.5 1.5 0 1 0-3 0v.7C7.6 5.4 6 7.9 6 11v5l-2 2v1h16v-1l-2-2Z"
      />
    </svg>
  )
}

export default NotificationIcon

import { lazy } from 'react'

const ErrorElementRoute = lazy(
  () => import('@/components/exception/error-element-route')
)

const PullingESPRequestUpdatePage = lazy(
  () => import('@/pages/pulling/esp/detail/request/update')
)
const PullingESPCreateNewRequestPage = lazy(
  () => import('@/pages/pulling/esp/detail/add-new')
)
const PullingESPRequestDetailPage = lazy(
  () => import('@/pages/pulling/esp/detail/request')
)
const PullingESPPage = lazy(() => import('@/pages/pulling/esp'))
const PullingESPDetailPage = lazy(() => import('@/pages/pulling/esp/detail'))

const PullingHPSRequestUpdatePage = lazy(
  () => import('@/pages/pulling/hps/detail/request/update')
)
const PullingHPSCreateNewRequestPage = lazy(
  () => import('@/pages/pulling/hps/detail/add-new')
)
const PullingHPSRequestDetailPage = lazy(
  () => import('@/pages/pulling/hps/detail/request')
)
const PullingHPSPage = lazy(() => import('@/pages/pulling/hps'))
const PullingHPSDetailPage = lazy(() => import('@/pages/pulling/hps/detail'))

const PullingHPUPage = lazy(() => import('@/pages/pulling/hpu'))
const PullingHPUDetailPage = lazy(() => import('@/pages/pulling/hpu/detail'))
const PullingHPUCreateNewRequestPage = lazy(
  () => import('@/pages/pulling/hpu/detail/add-new')
)
const PullingHPURequestDetailPage = lazy(
  () => import('@/pages/pulling/hpu/detail/request')
)
const PullingHPURequestUpdatePage = lazy(
  () => import('@/pages/pulling/hpu/detail/request/update')
)

const PullingLESPPage = lazy(() => import('@/pages/pulling/lesp'))
const PullingLESPDetailPage = lazy(() => import('@/pages/pulling/lesp/detail'))
const PullingLESPCreateNewRequestPage = lazy(
  () => import('@/pages/pulling/lesp/detail/add-new')
)
const PullingLESPRequestDetailPage = lazy(
  () => import('@/pages/pulling/lesp/detail/request')
)
const PullingLESPRequestUpdatePage = lazy(
  () => import('@/pages/pulling/lesp/detail/request/update')
)

const PullingGENSETPage = lazy(() => import('@/pages/pulling/genset'))
const PullingGENSETDetailPage = lazy(
  () => import('@/pages/pulling/genset/detail')
)
const PullingGENSETCreateNewRequestPage = lazy(
  () => import('@/pages/pulling/genset/detail/add-new')
)
const PullingGENSETRequestDetailPage = lazy(
  () => import('@/pages/pulling/genset/detail/request')
)
const PullingGENSETRequestUpdatePage = lazy(
  () => import('@/pages/pulling/genset/detail/request/update')
)

const pullingRoutes = {
  path: 'pulling',
  errorElement: <ErrorElementRoute />,
  children: [
    {
      path: 'esp',
      children: [
        {
          index: true,
          element: <PullingESPPage />,
        },
        {
          path: ':id',
          element: <PullingESPDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <PullingESPCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <PullingESPRequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <PullingESPRequestUpdatePage />,
        },
      ],
    },
    {
      path: 'hps',
      children: [
        {
          index: true,
          element: <PullingHPSPage />,
        },
        {
          path: ':id',
          element: <PullingHPSDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <PullingHPSCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <PullingHPSRequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <PullingHPSRequestUpdatePage />,
        },
      ],
    },
    {
      path: 'hpu',
      children: [
        {
          index: true,
          element: <PullingHPUPage />,
        },
        {
          path: ':id',
          element: <PullingHPUDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <PullingHPUCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <PullingHPURequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <PullingHPURequestUpdatePage />,
        },
      ],
    },
    {
      path: 'lesp',
      children: [
        {
          index: true,
          element: <PullingLESPPage />,
        },
        {
          path: ':id',
          element: <PullingLESPDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <PullingLESPCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <PullingLESPRequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <PullingLESPRequestUpdatePage />,
        },
      ],
    },
    {
      path: 'lesp',
      children: [
        {
          index: true,
          element: <PullingLESPPage />,
        },
        {
          path: ':id',
          element: <PullingLESPDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <PullingLESPCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <PullingLESPRequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <PullingLESPRequestUpdatePage />,
        },
      ],
    },
    {
      path: 'genset',
      children: [
        {
          index: true,
          element: <PullingGENSETPage />,
        },
        {
          path: ':id',
          element: <PullingGENSETDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <PullingGENSETCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <PullingGENSETRequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <PullingGENSETRequestUpdatePage />,
        },
      ],
    },
  ],
}

export default pullingRoutes

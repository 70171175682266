import { Input } from 'antd'
import { useDebouncedCallback } from 'use-debounce'
import styles from './simple-input-table-filter.module.css'
import SearchIcon from '@/assets/icons/search-icon'

function FilterTableSimpleInput({
  onChange,
  value,
  wait = 1000,
  disabled = false,
}: {
  onChange: (value: string) => void
  value: string
  wait?: number
  disabled?: boolean
}) {
  const debounced = useDebouncedCallback((inputValue: string) => {
    onChange?.(inputValue)
  }, wait)

  return (
    <Input
      prefix={
        <SearchIcon
          width={20}
          height={20}
          style={{ color: 'var(--primary-main)' }}
        />
      }
      classNames={{ input: styles.Input, affixWrapper: styles.AffixWrapper }}
      styles={{
        affixWrapper: {
          '--ant-color-bg-container': '#F1F4F9',
          '--ant-color-border': '#F1F4F9',
          '--ant-input-padding-inline': '16px',
          '--ant-padding-xxs': '8px',
        } as never,
      }}
      placeholder="Search"
      style={{ width: 340 }}
      size="middle"
      allowClear
      defaultValue={value}
      onChange={(e) => {
        if (e.target.value) {
          debounced(e.target.value)
        } else {
          onChange?.(e.target.value)
        }
      }}
      disabled={disabled}
    />
  )
}

export default FilterTableSimpleInput

import { Suspense, lazy } from 'react'

const ErrorElementRoute = lazy(
  () => import('@/components/exception/error-element-route')
)

const WrapperPMAndPDMPage = lazy(
  () => import('@/pages/pm-and-pdm/components/wrapper')
)
const PMPage = lazy(() => import('@/pages/pm-and-pdm/pm'))
const PMCreatePage = lazy(() => import('@/pages/pm-and-pdm/pm/create'))
const PMDetailPage = lazy(() => import('@/pages/pm-and-pdm/pm/detail'))
const PMDetailReportPage = lazy(
  () => import('@/pages/pm-and-pdm/pm/report/detail-report')
)
const PMEditReportPage = lazy(
  () => import('@/pages/pm-and-pdm/pm/report/edit-report')
)
const PMUpdatePage = lazy(() => import('@/pages/pm-and-pdm/pm/update'))
const PdMPage = lazy(() => import('@/pages/pm-and-pdm/pdm'))
const PdMDetailPage = lazy(() => import('@/pages/pm-and-pdm/pdm/detail'))
const PdMUpdatePage = lazy(() => import('@/pages/pm-and-pdm/pdm/update'))
const PdMDetailReportPage = lazy(
  () => import('@/pages/pm-and-pdm/pdm/report/detail-report')
)
const PdMEditReportPage = lazy(
  () => import('@/pages/pm-and-pdm/pdm/report/edit-report')
)
const pmAndPdmRoutes = {
  path: 'pm-and-pdm',
  errorElement: <ErrorElementRoute />,
  element: (
    <Suspense fallback="">
      <WrapperPMAndPDMPage />
    </Suspense>
  ),
  children: [
    {
      path: 'pm',
      children: [
        {
          index: true,
          element: <PMPage />,
        },
        {
          path: 'add-new',
          element: <PMCreatePage />,
        },
        {
          path: ':id',
          element: <PMDetailPage />,
        },
        {
          path: ':id/edit',
          element: <PMUpdatePage />,
        },
        {
          path: ':id/report/:idReport',
          element: <PMEditReportPage />,
        },
        {
          path: ':id/report/:idReport/detail',
          element: <PMDetailReportPage />,
        },
      ],
    },
    {
      path: 'pdm',
      children: [
        {
          index: true,
          element: <PdMPage />,
        },
        {
          path: ':id',
          element: <PdMDetailPage />,
        },
        {
          path: ':id/edit',
          element: <PdMUpdatePage />,
        },
        {
          path: ':id/report/:idReport',
          element: <PdMEditReportPage />,
        },
        {
          path: ':id/report/:idReport/detail',
          element: <PdMDetailReportPage />,
        },
      ],
    },
  ],
}

export default pmAndPdmRoutes

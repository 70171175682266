import React from 'react'

function EditIcon({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M3 17.3V21h3.8l11-11-3.7-3.8L3 17.2ZM20.7 7c.4-.3.4-1 0-1.4l-2.3-2.3a1 1 0 0 0-1.4 0L15 5 19 9 20.7 7Z"
      />
    </svg>
  )
}

export default EditIcon

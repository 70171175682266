import { lazy } from 'react'

const ErrorElementRoute = lazy(
  () => import('@/components/exception/error-element-route')
)

const TroubleshootESPRequestUpdatePage = lazy(
  () => import('@/pages/troubleshoot/esp/detail/request/update')
)
const TroubleshootESPCreateNewRequestPage = lazy(
  () => import('@/pages/troubleshoot/esp/detail/add-new')
)
const TroubleshootESPRequestDetailPage = lazy(
  () => import('@/pages/troubleshoot/esp/detail/request')
)
const TroubleshootESPPage = lazy(() => import('@/pages/troubleshoot/esp'))
const TroubleshootESPDetailPage = lazy(
  () => import('@/pages/troubleshoot/esp/detail')
)

const TroubleshootHPSRequestUpdatePage = lazy(
  () => import('@/pages/troubleshoot/hps/detail/request/update')
)
const TroubleshootHPSCreateNewRequestPage = lazy(
  () => import('@/pages/troubleshoot/hps/detail/add-new')
)
const TroubleshootHPSRequestDetailPage = lazy(
  () => import('@/pages/troubleshoot/hps/detail/request')
)
const TroubleshootHPSPage = lazy(() => import('@/pages/troubleshoot/hps'))
const TroubleshootHPSDetailPage = lazy(
  () => import('@/pages/troubleshoot/hps/detail')
)

const TroubleshootHPUPage = lazy(() => import('@/pages/troubleshoot/hpu'))
const TroubleshootHPUDetailPage = lazy(
  () => import('@/pages/troubleshoot/hpu/detail')
)
const TroubleshootHPUCreateNewRequestPage = lazy(
  () => import('@/pages/troubleshoot/hpu/detail/add-new')
)
const TroubleshootHPURequestDetailPage = lazy(
  () => import('@/pages/troubleshoot/hpu/detail/request')
)
const TroubleshootHPURequestUpdatePage = lazy(
  () => import('@/pages/troubleshoot/hpu/detail/request/update')
)

const TroubleshootLESPPage = lazy(() => import('@/pages/troubleshoot/lesp'))
const TroubleshootLESPDetailPage = lazy(
  () => import('@/pages/troubleshoot/lesp/detail')
)
const TroubleshootLESPCreateNewRequestPage = lazy(
  () => import('@/pages/troubleshoot/lesp/detail/add-new')
)
const TroubleshootLESPRequestDetailPage = lazy(
  () => import('@/pages/troubleshoot/lesp/detail/request')
)
const TroubleshootLESPRequestUpdatePage = lazy(
  () => import('@/pages/troubleshoot/lesp/detail/request/update')
)

const TroubleshootGENSETPage = lazy(() => import('@/pages/troubleshoot/genset'))
const TroubleshootGENSETDetailPage = lazy(
  () => import('@/pages/troubleshoot/genset/detail')
)
const TroubleshootGENSETCreateNewRequestPage = lazy(
  () => import('@/pages/troubleshoot/genset/detail/add-new')
)
const TroubleshootGENSETRequestDetailPage = lazy(
  () => import('@/pages/troubleshoot/genset/detail/request')
)
const TroubleshootGENSETRequestUpdatePage = lazy(
  () => import('@/pages/troubleshoot/genset/detail/request/update')
)

const troubleshootRoutes = {
  path: 'troubleshoot',
  errorElement: <ErrorElementRoute />,
  children: [
    {
      path: 'esp',
      children: [
        {
          index: true,
          element: <TroubleshootESPPage />,
        },
        {
          path: ':id',
          element: <TroubleshootESPDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <TroubleshootESPCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <TroubleshootESPRequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <TroubleshootESPRequestUpdatePage />,
        },
      ],
    },
    {
      path: 'hps',
      children: [
        {
          index: true,
          element: <TroubleshootHPSPage />,
        },
        {
          path: ':id',
          element: <TroubleshootHPSDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <TroubleshootHPSCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <TroubleshootHPSRequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <TroubleshootHPSRequestUpdatePage />,
        },
      ],
    },
    {
      path: 'hpu',
      children: [
        {
          index: true,
          element: <TroubleshootHPUPage />,
        },
        {
          path: ':id',
          element: <TroubleshootHPUDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <TroubleshootHPUCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <TroubleshootHPURequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <TroubleshootHPURequestUpdatePage />,
        },
      ],
    },
    {
      path: 'lesp',
      children: [
        {
          index: true,
          element: <TroubleshootLESPPage />,
        },
        {
          path: ':id',
          element: <TroubleshootLESPDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <TroubleshootLESPCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <TroubleshootLESPRequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <TroubleshootLESPRequestUpdatePage />,
        },
      ],
    },
    {
      path: 'lesp',
      children: [
        {
          index: true,
          element: <TroubleshootLESPPage />,
        },
        {
          path: ':id',
          element: <TroubleshootLESPDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <TroubleshootLESPCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <TroubleshootLESPRequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <TroubleshootLESPRequestUpdatePage />,
        },
      ],
    },
    {
      path: 'genset',
      children: [
        {
          index: true,
          element: <TroubleshootGENSETPage />,
        },
        {
          path: ':id',
          element: <TroubleshootGENSETDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <TroubleshootGENSETCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <TroubleshootGENSETRequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <TroubleshootGENSETRequestUpdatePage />,
        },
      ],
    },
  ],
}

export default troubleshootRoutes

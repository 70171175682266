import React from 'react'

const TrackChangesIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="m19 5-1.3 1.3A8 8 0 1 1 11 4.1v2a6 6 0 1 0 5.2 1.7l-1.4 1.4a4 4 0 1 1-3.8-1v2c-.6.4-1 1-1 1.8 0 1.1.9 2 2 2a2 2 0 0 0 2-2 2 2 0 0 0-1-1.7V2h-1a10 10 0 1 0 7 3Z"
      />
    </svg>
  )
}

export default TrackChangesIcon

import React from 'react'

const ManageAccountsIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10 12c-1.1 0-2-.4-2.8-1.2A3.9 3.9 0 0 1 6 8c0-1.1.4-2 1.2-2.8C8 4.4 8.9 4 10 4c1.1 0 2 .4 2.8 1.2C13.6 6 14 6.9 14 8c0 1.1-.4 2-1.2 2.8-.8.8-1.7 1.2-2.8 1.2Zm-8 8v-2.8c0-.6.1-1 .4-1.5.3-.5.7-.9 1.2-1.1.9-.5 1.8-.8 2.9-1.2 1-.3 2.2-.4 3.5-.4h.6a7 7 0 0 0-.5 2H10c-1.2 0-2.2.2-3.2.4-1 .3-1.7.7-2.3 1a1 1 0 0 0-.5.8v.8h6.3a6.3 6.3 0 0 0 1 2H2Zm14 1-.3-1.5a7 7 0 0 1-1.1-.6l-1.4.5-1-1.8 1.1-1a4.6 4.6 0 0 1 0-1.3l-1.2-1 1-1.7 1.5.5a4.4 4.4 0 0 1 1.1-.6L16 11h2l.3 1.5a5 5 0 0 1 1.1.7l1.5-.5 1 1.7-1.2 1a3.8 3.8 0 0 1 0 1.2l1.2 1-1 1.8-1.5-.5a4.4 4.4 0 0 1-1.1.6L18 21h-2Zm1-3c.6 0 1-.2 1.4-.6.4-.4.6-.8.6-1.4 0-.6-.2-1-.6-1.4A2 2 0 0 0 17 14a2 2 0 0 0-1.4.6A2 2 0 0 0 15 16c0 .6.2 1 .6 1.4.4.4.8.6 1.4.6Zm-7-8c.6 0 1-.2 1.4-.6.4-.4.6-.8.6-1.4 0-.5-.2-1-.6-1.4A2 2 0 0 0 10 6a2 2 0 0 0-1.4.6A2 2 0 0 0 8 8c0 .6.2 1 .6 1.4.4.4.8.6 1.4.6Z"
      />
    </svg>
  )
}

export default ManageAccountsIcon

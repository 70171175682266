import { Avatar, Button, Dropdown, Layout, Menu, MenuProps, Spin } from 'antd'
import { Suspense, useState } from 'react'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import useSignOut from 'react-auth-kit/hooks/useSignOut'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import KeyboardArrowDownIcon from '@/assets/icons/keyboard-arrow-down-icon'
import MenuIcon from '@/assets/icons/menu-icon'
import MenuOpenIcon from '@/assets/icons/menu-open-icon'
import NotificationIcon from '@/assets/icons/notification-icon'
import { cn } from '@/lib/utils'
import styles from './dashboard-layout.module.css'
import useMenu from './useMenu'
import { User } from '@/types/user'

const { Header, Content, Sider } = Layout

function DashboardLayout() {
  const signOut = useSignOut()
  const auth = useAuthUser<User>()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [collapsed, setCollapsed] = useState(false)

  const menus = useMenu()

  const items: MenuProps['items'] = [
    {
      label: 'Profile',
      key: 'Profile',
    },
    {
      label: 'Logout',
      key: 'logout',
      danger: true,
      onClick: () => {
        signOut()
        navigate('/auth/login')
      },
    },
  ]

  const menuProps = {
    items,
  }

  const paths = pathname
    .split('/')
    .filter((item) => item !== '')
    .reduce((prev: string[], curr) => {
      if (prev.length === 0) {
        prev.push(`/${curr}`)
      } else {
        prev.push(`${prev[prev.length - 1]}/${curr}`)
      }
      return prev
    }, [])

  return (
    <Layout>
      <Sider
        width={260}
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          overflow: 'hidden',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 999,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#fff',
            height: 100,
            padding: '20px 24px',
          }}
        >
          <img
            src={collapsed ? '/ejp-logo-only.png' : '/ejp-logo.png'}
            width={collapsed ? 40 : 163}
            height={collapsed ? 40 : 52}
            alt="EJP logo"
          />
        </div>
        <Menu
          className={cn(styles.SidebarMenu, {
            [styles.SidebarMenuCollapsed]: collapsed,
          })}
          defaultOpenKeys={paths}
          defaultSelectedKeys={paths}
          mode="inline"
          items={menus.map((menu) => ({
            ...menu,
            className:
              menu.children && menu.children?.length > 0
                ? styles.SidebarSubMenuItem
                : styles.SidebarMenuItem,
          }))}
        />
      </Sider>
      <Layout style={{ marginLeft: collapsed ? 80 : 260 }}>
        <Header
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxShadow: '0px 7px 12px 0px rgba(152, 152, 152, 0.05)',
            overflow: 'auto',
            position: 'fixed',
            left: collapsed ? 80 : 260,
            right: 0,
            top: 0,
            zIndex: 998,
          }}
        >
          <Button
            type="text"
            onClick={() => setCollapsed(!collapsed)}
            style={{ marginLeft: -12 }}
          >
            {collapsed ? (
              <MenuOpenIcon width={24} height={24} />
            ) : (
              <MenuIcon width={24} height={24} />
            )}
          </Button>
          <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
            <Button
              style={{
                borderWidth: 0,
                color: 'var(--primary-main)',
                boxShadow: '0px 5px 20px 0px rgba(0, 0, 0, 0.05)',
              }}
              icon={<NotificationIcon width={24} height={24} />}
            />

            <Dropdown menu={menuProps}>
              <Button type="link" style={{ padding: 0 }}>
                <div>
                  <p
                    style={{
                      fontSize: 12,
                      color: 'var(--neutral-60)',
                      fontWeight: 600,
                    }}
                  >
                    Welcome
                  </p>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p
                      style={{
                        fontSize: 16,
                        color: 'var(--neutral-100)',
                        fontWeight: 500,
                      }}
                    >
                      {auth?.fullname}
                    </p>
                    <KeyboardArrowDownIcon
                      width={24}
                      height={24}
                      style={{ color: 'var(--neutral-70)' }}
                    />
                  </div>
                </div>
                <Avatar size={48} src="/assets/images/profile.png" />
              </Button>
            </Dropdown>
          </div>
        </Header>
        <Content className={styles.Content}>
          <Suspense fallback={<Spin fullscreen />}>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  )
}

export default DashboardLayout

import { ThemeConfig } from 'antd'

const theme = {
  cssVar: true,
  // hashed: false,
  components: {
    Button: {
      // small button
      controlHeightSM: 40,
      paddingInlineSM: 8,
      paddingBlockSM: 8,
      fontSizeSM: 16,

      // medium button
      paddingInline: 12,
      paddingBlock: 12,

      // lg button
      paddingInlineLG: 16,
      paddingBlockLG: 16,
      fontSizeLG: 16,
      contentLineHeightLG: 1.5,

      // outline
      defaultShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      defaultBorderColor: '#EBEBEB',
      lineWidth: 1.5,

      // general
      borderRadius: 8,
      fontSize: 16,
      primaryShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      colorPrimaryHover: 'var(--primary-700)',
      colorPrimaryActive: 'var(--primary-800)',
      contentLineHeight: 1.5,
      fontWeight: 500,

      // danger button
      colorError: 'var(--danger-main)',
      colorErrorHover: 'var(--danger-hover)',
      colorErrorActive: 'var(--danger-pressed)',
      colorErrorBorder: 'var(--danger-border)',

      // outline
      defaultGhostBorderColor: 'var(--neutral-30)',
      defaultGhostColor: 'var(--neutral-80)',
    },
    Layout: {
      headerBg: '#fff',
      headerHeight: 100,
      bodyBg: 'var(--primary-100)',
      headerPadding: '20px 32px',
      siderBg: 'var(--primary-700)',
    },
    Typography: {
      colorText: 'var(--neutral-90)',
      fontSize: 16,
      lineHeight: 1.5,
      colorTextHeading: 'var(--neutral-90)',
      fontSizeHeading1: 44,
      lineHeightHeading1: 1.47,
      fontSizeHeading2: 36,
      lineHeightHeading2: 1.4,
      fontSizeHeading3: 32,
      lineHeightHeading3: 1.125,
      fontSizeHeading4: 28,
      lineHeightHeading4: 1.185,
      fontSizeHeading5: 24,
      lineHeightHeading5: 1.167,
    },
    Input: {
      borderRadius: 8,
      colorBorder: 'var(--neutral-30)',
      paddingInline: 20,
      paddingInlineLG: 20,
      activeShadow: '0 0 0 1px rgba(25, 66, 145, 1)',
      lineWidth: 1.5,
      hoverBorderColor: 'var(--primary-main)',
      fontSize: 14,
      fontSizeLG: 14,
    },
    InputNumber: {
      borderRadius: 8,
      colorBorder: 'var(--neutral-30)',
      paddingInline: 20,
      paddingInlineLG: 20,

      activeShadow: '0 0 0 1px rgba(25, 66, 145, 1)',
      lineWidth: 1.5,
      hoverBorderColor: 'var(--primary-main)',
      fontSize: 14,
      fontSizeLG: 14,
      // controlWidth: '100%' as any,
    },
    Select: {
      borderRadius: 8,
      colorBorder: 'var(--neutral-30)',
      lineWidth: 1.5,
      paddingSM: 20,
      fontSize: 14,
      fontSizeLG: 14,
      controlOutline: 'var(--primary-main)',
      controlOutlineWidth: 1,

      multipleItemBg: 'var(--primary-100)',
      multipleItemBorderColor: 'var(--primary-200)',
      multipleItemBorderColorDisabled: 'var(--primary-200)',
      multipleItemHeight: 24,
      multipleItemHeightLG: 24,
      colorTextPlaceholder: 'var(--neutral-90)',
    },
    DatePicker: {
      controlHeightLG: 60.79,
      paddingInline: 20,
      paddingInlineLG: 20,
      lineWidth: 1.5,
      hoverBorderColor: 'var(--primary-main)',
      activeShadow: '0 0 0 1px rgba(25, 66, 145, 1)',
      fontSize: 14,
      fontSizeLG: 14,
      colorTextPlaceholder: 'var(--neutral-90)',
    },
    Form: {
      labelHeight: 24,
      labelColor: 'var(--neutral-90)',
      labelRequiredMarkColor: 'var(--danger-main)',
    },
    Modal: {
      colorText: 'var(--neutral-90)',
    },
    Menu: {
      itemBg: 'transparent',
      itemColor: '#fff',
      itemSelectedBg: '#fff',
      itemSelectedColor: 'var(--primary-main)',
      subMenuItemBg: 'transparent',
      itemHeight: 44,
      activeBarBorderWidth: 0,
      itemBorderRadius: 4,
      fontSize: 16,
      colorText: '#fff',
      popupBg: 'var(--primary-main)',
    },
    Breadcrumb: {
      itemColor: 'var(--neutral-90)',
      lastItemColor: 'var(--primary-main)',
      linkHoverColor: 'var(--primary-main)',
      colorBgTextHover: 'transparent',
      linkColor: 'var(--neutral-90)',
      separatorColor: 'var(--neutral-90)',
      separatorMargin: 1,
      lineHeight: 1.4,
    },
    Card: {
      paddingLG: 32,
      borderRadiusLG: 16,
    },
    Table: {
      headerBg: '#F8F9FC',
      headerColor: 'var(--neutral-90)',
      headerSplitColor: 'transparent',
      cellFontSize: 14,
      cellFontSizeMD: 14,
      colorText: 'var(--neutral-80)',
      borderColor: '#E6E7EB',
      headerBorderRadius: 12,
      rowSelectedBg: 'transparent',
      rowSelectedHoverBg: 'transparent',
      // select row padding
      paddingXS: 16,
      // sorting
      bodySortBg: 'transparent',
      headerSortHoverBg: 'var(--primary-100)',
      headerSortActiveBg: '#F8F9FC',
    },
    Tabs: {
      cardBg: 'var(--neutral-10)',
      itemColor: 'var(--neutral-50)',
      itemSelectedColor: 'var(--primary-main)',
      itemHoverColor: 'var(--primary-main)',
      inkBarColor: 'transparent',
      colorBorderSecondary: '#fff',
      cardHeight: 52,
      titleFontSize: 14,
      fontSizeLG: 14,
      cardGutter: 4,
      cardPaddingLG: '16px 12px',
      horizontalMargin: '0px',
    },
    Tag: {
      colorSuccess: 'var(--success-hover)',
      colorSuccessBg: 'var(--success-surface)',
      colorSuccessBorder: 'var(--success-border)',
      colorWarning: 'var(--warning-hover)',
      colorWarningBg: 'var(--warning-surface)',
      colorWarningBorder: 'var(--warning-border)',
      colorError: 'var(--danger-hover)',
      colorErrorBg: 'var(--danger-surface)',
      colorErrorBorder: 'var(--danger-border)',
      fontSize: 12,
      fontSizeSM: 12,
      lineHeightSM: 1.33333,
    },
    Pagination: {
      colorPrimaryBorder: 'var(--primary-main)',
      itemActiveBg: 'var(--primary-main)',
      colorPrimaryHover: '#fff',
      colorPrimary: '#fff',
      itemSize: 28,
    },
    Space: {
      paddingXS: 12,
    },
    Checkbox: {
      controlInteractiveSize: 20,
      colorBorder: 'var(--neutral-30)',
    },
    Switch: {
      colorPrimary: 'var(--success-main)',
      colorPrimaryHover: 'var(--success-hover)',
      colorPrimaryBorder: 'var(--success-border)',
    },
    Collapse: {
      contentPadding: '32px',
      headerPadding: '24px 32px',
      headerBg: '#F8F9FC',
      colorBorder: 'var(--neutral-20)',
      colorTextHeading: 'var(--neutral-80)',
    },
    Steps: {
      lineWidth: 10,
      iconSize: 40,
    },
    List: {
      headerBg: '#F8F9FC',
      itemPaddingLG: '16px 16px',
    },
    Divider: {
      lineWidth: 1.5,
      colorSplit: 'var(--neutral-30)',
    },
    Segmented: {
      itemSelectedBg: 'var(--primary-main)',
      colorText: 'white',
      itemColor: 'var(--neutral-50)',
      trackBg: 'var(--neutral-10)',
      itemHoverColor: 'var(--neutral-60)',
      itemHoverBg: 'var(--neutral-20)',
      trackPadding: 4,
    },
    Badge: {
      dotSize: 8,
      indicatorHeight: 24,
      textFontSize: 16,
      textFontWeight: '500',
      fontSize: 14,
    },
    Skeleton: {
      sizeLG: 56,
    },
    Spin: {
      colorBgMask: 'rgba(0, 0, 0, 0.3)',
    },
  },
  token: {
    colorPrimary: 'rgb(25 66 145)', // --primary-main
    fontSize: 16,
    fontFamily: 'var(--font-inter)',
    colorLink: 'rgb(25 66 145)', // --primary-main
    colorLinkHover: 'rgb(19 52 113)', // --primary-700
    colorLinkActive: 'rgb(15 40 87)', // --primary-800
    colorBgContainerDisabled: 'var(--neutral-10)',
    // size medium
    controlHeight: 48,
    // size large
    controlHeightLG: 56,
  },
} satisfies ThemeConfig

export default theme

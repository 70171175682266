import { App, ConfigProvider, ThemeConfig } from 'antd'
import React from 'react'
import defaultTheme from '@/config/theme'
import AntdWrapApp from '@/components/antd-global-app'

function ThemeProvider({
  theme = defaultTheme,
  children,
}: {
  theme?: ThemeConfig
  children: React.ReactNode
}) {
  return (
    <ConfigProvider componentSize="large" theme={theme}>
      <App notification={{ placement: 'bottom' }}>
        <AntdWrapApp />
        {children}
      </App>
    </ConfigProvider>
  )
}

export default ThemeProvider

import RequireAuth from '@auth-kit/react-router/RequireAuth'
import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import DashboardLayout from '@/layouts/dashboard-layout'
import assetInsightRoutes from './asset-insight-route'
import authRoutes from './auth-route'
import installationRoutes from './installation-route'
import managementRoutes from './management-route'
import masterDataRoutes from './master-data-route'
import pmAndPdmRoutes from './pm-and-pdm-route'
import pullingRoutes from './pulling-route'
import troubleshootRoutes from './troubleshoot-route'

const ErrorElementRoute = lazy(
  () => import('@/components/exception/error-element-route')
)
const NotFoundPage = lazy(() => import('@/pages/not-found'))
const routes = createBrowserRouter([
  authRoutes,
  {
    path: '/',
    element: (
      <RequireAuth fallbackPath="/auth/login">
        <DashboardLayout />
      </RequireAuth>
    ),
    errorElement: <ErrorElementRoute />,
    children: [
      installationRoutes,
      troubleshootRoutes,
      pullingRoutes,
      assetInsightRoutes,
      pmAndPdmRoutes,
      managementRoutes,
      masterDataRoutes,
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
])

export default routes

import {
  keepPreviousData,
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import api from '@/lib/axios'
import {
  APIResponse,
  APIResponseWithoutTotal,
  RequestParams,
} from '@/types/general'
import {
  CreateAssetInsightWriteOffDto,
  AssetInsightWriteOff,
} from '@/types/write-off'

const fetchWriteOffs = async (filters: RequestParams) => {
  const response = await api.get('/asset-insight/write-off', {
    params: filters,
  })
  return response.data as APIResponse<AssetInsightWriteOff[]>
}
const fetchWriteOff = async (id: string | undefined) => {
  const response = await api.get(`/asset-insight/write-off/${id}`)
  return response.data as APIResponseWithoutTotal<AssetInsightWriteOff>
}

const createWriteOff = async (payload: CreateAssetInsightWriteOffDto) => {
  const response = await api.post('/asset-insight/write-off', payload)
  return response.data as AssetInsightWriteOff
}
const updateWriteOff = async (
  id: string | undefined,
  payload: CreateAssetInsightWriteOffDto
) => {
  const response = await api.put(`/asset-insight/write-off/${id}`, payload)
  return response.data as AssetInsightWriteOff
}
const deleteWriteOff = async (id: string | string[]) => {
  const ids = typeof id === 'string' ? [id] : id
  return api.post(`/asset-insight/write-off/multiple/force-delete`, { ids })
}

const queires = {
  all: () => ['write offs'],
  lists: () => [...queires.all(), 'list'],
  list: (filters: RequestParams) =>
    queryOptions({
      queryKey: [...queires.lists(), filters],
      queryFn: () => fetchWriteOffs(filters),
      placeholderData: keepPreviousData,
    }),
  details: () => [...queires.all(), 'detail'],
  detail: (id: string | undefined) =>
    queryOptions({
      queryKey: [...queires.details(), id],
      queryFn: () => fetchWriteOff(id),
      enabled: Boolean(id),
      staleTime: 5000,
    }),
}

export const useWriteOffs = (filter: RequestParams) => {
  return useQuery(queires.list(filter))
}
export const useWriteOff = (id: string | undefined) => {
  return useQuery(queires.detail(id))
}
export const useCreateWriteOff = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: CreateAssetInsightWriteOffDto) =>
      createWriteOff(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queires.lists(),
      })
    },
  })
}
export const useUpdateWriteOff = (id: string | undefined) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: CreateAssetInsightWriteOffDto) =>
      updateWriteOff(id, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queires.lists(),
      })
    },
  })
}
export const useDeleteWriteOff = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string | string[]) => deleteWriteOff(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queires.lists(),
      })
    },
  })
}

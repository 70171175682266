import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from 'react-router-dom'
import theme from './config/theme'
import ThemeProvider from './provider/theme-provider'
import routes from './routes'
import AuthProvider from './provider/auth-provider'

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 1 } },
})

function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={routes} />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AuthProvider>
  )
}

export default App

/* eslint-disable react/no-unstable-nested-components */

import { Button, Flex, Table, TablePaginationConfig, TableProps } from 'antd'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AnyObject } from 'antd/es/_util/type'
import { SortOrder, SorterResult } from 'antd/es/table/interface'
import { isArray } from 'lodash-es'
import DeleteOutlineIcon from '@/assets/icons/delete-outline-icon'
import { notification } from '../antd-global-app'
import { showDeleteConfirm } from '../popup'
import Select from '../select'
import styles from './data-table.module.css'

function DataTable<T extends AnyObject>({
  dataSource,
  columns,
  rowKey = 'id',
  total = 0,
  rowSelectable = true,
  pagination = false,
  bulkDeleteFunction = undefined,
  ...tableProps
}: TableProps<T> & {
  bulkDeleteFunction?: (data: T[]) => Promise<unknown>
  total?: number
  rowSelectable?: boolean
  pagination?: TablePaginationConfig & {
    pageKey?: string
    pageSizeKey?: string
  }
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedRowData, setSelectedRowData] = useState<T[]>([])
  const onSelectChange = (_: React.Key[], selectedRows: T[]) => {
    setSelectedRowData(selectedRows)
  }

  const rowSelection = {
    selectedRowKeys: selectedRowData.map(
      (item) => (item as never)[rowKey as never]
    ),
    onChange: onSelectChange,
  }
  const hasSelected = selectedRowData.length > 0
  let paginationConfig: TablePaginationConfig = {
    showSizeChanger: false,
    showTotal: (totalPage, range) => (
      <Flex gap={12} align="center">
        <Select
          size="middle"
          options={[
            { value: '5', label: '5 Entries' },
            { value: '10', label: '10 Entries' },
            { value: '20', label: '20 Entries' },
            { value: '50', label: '50 Entries' },
          ]}
          style={
            {
              '--ant-color-text': 'var(--neutral-80)',
              fontWeight: 500,
              '--ant-font-size': '16px',
              '--ant-padding-sm': '12px',
              '--ant-color-text-quaternary': 'var(--neutral-80)',
            } as never
          }
          value={
            pagination
              ? `${pagination.pageSize}`
              : searchParams.get('size') || '10'
          }
          onChange={(value) => {
            if (pagination) {
              pagination?.onChange?.(1, value)
            } else {
              setSearchParams({ page: '1', size: `${value}` })
            }
          }}
        />
        <span style={{ fontSize: 14 }}>
          Showing {range[0]} to {range[1]} of {totalPage} entries
        </span>
      </Flex>
    ),
  }
  if (pagination) {
    paginationConfig = { ...paginationConfig, ...pagination }
  } else {
    const currentPage = searchParams.get('page') || '1'
    const currentPageSize = searchParams.get('size') || '10'
    paginationConfig = {
      ...paginationConfig,
      total,
      pageSize: Number(currentPageSize),
      current: Number(currentPage),
      onChange: (page) => {
        searchParams.set('page', `${page}`)
        setSearchParams(searchParams)
      },
    }
  }

  const order = searchParams.get('order')?.toUpperCase()
  const sort = searchParams.get('sort')

  return (
    <div style={{ position: 'relative' }}>
      {bulkDeleteFunction ? (
        <div className={styles.BulkActionsContainer}>
          <Button
            type="primary"
            disabled={!hasSelected}
            size="middle"
            danger
            icon={<DeleteOutlineIcon width={20} height={20} />}
            style={{ minWidth: 140 }}
            onClick={() => {
              showDeleteConfirm({
                onOk: async () => {
                  try {
                    await bulkDeleteFunction?.(selectedRowData)
                    notification.success({
                      message: 'Success',
                      description: 'Items deleted successfully',
                    })
                    setSelectedRowData([])
                  } catch (error) {
                    notification.error({
                      message: 'Failed',
                      description: 'Something went wrong!',
                    })
                  }
                },
              })
            }}
          >
            Delete
          </Button>
        </div>
      ) : null}
      <Table<T>
        rowKey={rowKey}
        dataSource={dataSource}
        columns={
          columns?.map((col) => {
            let defaultSortOrder
            if (typeof col.sorter === 'boolean' && order && sort) {
              if (
                col.key === sort ||
                (col as { dataIndex: string }).dataIndex === sort
              ) {
                let orderTableColumn: SortOrder | undefined
                if (order && order === 'ASC') {
                  orderTableColumn = 'ascend'
                }
                if (order && order === 'DESC') {
                  orderTableColumn = 'descend'
                }
                defaultSortOrder = orderTableColumn
              }
            }
            return {
              ...col,
              defaultSortOrder,
            }
          }) || []
        }
        rowSelection={rowSelectable ? rowSelection : undefined}
        pagination={paginationConfig}
        onChange={(_pagination, _filters, sorter) => {
          const sortResult = sorter as SorterResult<T>
          if (sortResult) {
            if (sortResult.order) {
              // only execute when column sorter: true
              if (typeof sortResult.column?.sorter === 'boolean') {
                searchParams.set(
                  'sort',
                  `${isArray(sortResult.field) ? sortResult.field.join('.') : sortResult.field}`
                )
                searchParams.set(
                  'order',
                  `${sortResult.order === 'ascend' ? 'ASC' : 'DESC'}`
                )
              }
            } else {
              searchParams.delete('sort')
              searchParams.delete('order')
            }
            setSearchParams(searchParams)
          }
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...tableProps}
      />
    </div>
  )
}

export default DataTable

import React from 'react'

const MenuOpenIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M3 18h13v-2H3v2Zm0-5h10v-2H3v2Zm0-7v2h13V6H3Zm18 9.6L17.4 12 21 8.4 19.6 7l-5 5 5 5 1.4-1.4Z"
      />
    </svg>
  )
}

export default MenuOpenIcon

import { Suspense, lazy } from 'react'

const ErrorElementRoute = lazy(
  () => import('@/components/exception/error-element-route')
)

const AuthLayout = lazy(() => import('@/layouts/auth-layout'))
const ChangePasswordPage = lazy(() => import('@/pages/auth/change-password'))
const ForgotPasswordPage = lazy(() => import('@/pages/auth/forgot-password'))
const LoginPage = lazy(() => import('@/pages/auth/login'))

const authRoutes = {
  path: '/auth',
  errorElement: <ErrorElementRoute />,
  element: (
    <Suspense fallback="">
      <AuthLayout />
    </Suspense>
  ),
  children: [
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPasswordPage />,
    },
    {
      path: 'change-password',
      element: <ChangePasswordPage />,
    },
  ],
}

export default authRoutes

import { useSearchParams } from 'react-router-dom'

const useFilterSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const query = searchParams.get('query') || ''
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1
  const pageSize = searchParams.get('size')
    ? Number(searchParams.get('size'))
    : 10
  const sort = searchParams.get('sort') || ''
  const order =
    (searchParams.get('order')?.toUpperCase() as 'ASC' | 'DESC') || 'DESC'
  return [
    {
      query,
      page,
      pageSize,
      sort,
      order: order === 'ASC' || order === 'DESC' ? order : '',
      searchParams,
    },
    setSearchParams,
  ] as const
}

export default useFilterSearchParams

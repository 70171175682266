import { lazy } from 'react'

const ErrorElementRoute = lazy(
  () => import('@/components/exception/error-element-route')
)

const InstallationESPRequestUpdatePage = lazy(
  () => import('@/pages/installation/esp/detail/request/update')
)
const InstallationESPCreateNewRequestPage = lazy(
  () => import('@/pages/installation/esp/detail/add-new')
)
const InstallationESPRequestDetailPage = lazy(
  () => import('@/pages/installation/esp/detail/request')
)
const InstallationESPPage = lazy(() => import('@/pages/installation/esp'))
const InstallationESPDetailPage = lazy(
  () => import('@/pages/installation/esp/detail')
)

const InstallationHPSRequestUpdatePage = lazy(
  () => import('@/pages/installation/hps/detail/request/update')
)
const InstallationHPSCreateNewRequestPage = lazy(
  () => import('@/pages/installation/hps/detail/add-new')
)
const InstallationHPSRequestDetailPage = lazy(
  () => import('@/pages/installation/hps/detail/request')
)
const InstallationHPSPage = lazy(() => import('@/pages/installation/hps'))
const InstallationHPSDetailPage = lazy(
  () => import('@/pages/installation/hps/detail')
)

const InstallationHPUPage = lazy(() => import('@/pages/installation/hpu'))
const InstallationHPUDetailPage = lazy(
  () => import('@/pages/installation/hpu/detail')
)
const InstallationHPUCreateNewRequestPage = lazy(
  () => import('@/pages/installation/hpu/detail/add-new')
)
const InstallationHPURequestDetailPage = lazy(
  () => import('@/pages/installation/hpu/detail/request')
)
const InstallationHPURequestUpdatePage = lazy(
  () => import('@/pages/installation/hpu/detail/request/update')
)

const InstallationLESPPage = lazy(() => import('@/pages/installation/lesp'))
const InstallationLESPDetailPage = lazy(
  () => import('@/pages/installation/lesp/detail')
)
const InstallationLESPCreateNewRequestPage = lazy(
  () => import('@/pages/installation/lesp/detail/add-new')
)
const InstallationLESPRequestDetailPage = lazy(
  () => import('@/pages/installation/lesp/detail/request')
)
const InstallationLESPRequestUpdatePage = lazy(
  () => import('@/pages/installation/lesp/detail/request/update')
)

const InstallationGENSETPage = lazy(() => import('@/pages/installation/genset'))
const InstallationGENSETDetailPage = lazy(
  () => import('@/pages/installation/genset/detail')
)
const InstallationGENSETCreateNewRequestPage = lazy(
  () => import('@/pages/installation/genset/detail/add-new')
)
const InstallationGENSETRequestDetailPage = lazy(
  () => import('@/pages/installation/genset/detail/request')
)
const InstallationGENSETRequestUpdatePage = lazy(
  () => import('@/pages/installation/genset/detail/request/update')
)

const installationRoutes = {
  path: 'installation',
  errorElement: <ErrorElementRoute />,
  children: [
    {
      path: 'esp',
      children: [
        {
          index: true,
          element: <InstallationESPPage />,
        },
        {
          path: ':id',
          element: <InstallationESPDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <InstallationESPCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <InstallationESPRequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <InstallationESPRequestUpdatePage />,
        },
      ],
    },
    {
      path: 'hps',
      children: [
        {
          index: true,
          element: <InstallationHPSPage />,
        },
        {
          path: ':id',
          element: <InstallationHPSDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <InstallationHPSCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <InstallationHPSRequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <InstallationHPSRequestUpdatePage />,
        },
      ],
    },
    {
      path: 'hpu',
      children: [
        {
          index: true,
          element: <InstallationHPUPage />,
        },
        {
          path: ':id',
          element: <InstallationHPUDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <InstallationHPUCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <InstallationHPURequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <InstallationHPURequestUpdatePage />,
        },
      ],
    },
    {
      path: 'lesp',
      children: [
        {
          index: true,
          element: <InstallationLESPPage />,
        },
        {
          path: ':id',
          element: <InstallationLESPDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <InstallationLESPCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <InstallationLESPRequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <InstallationLESPRequestUpdatePage />,
        },
      ],
    },
    {
      path: 'lesp',
      children: [
        {
          index: true,
          element: <InstallationLESPPage />,
        },
        {
          path: ':id',
          element: <InstallationLESPDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <InstallationLESPCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <InstallationLESPRequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <InstallationLESPRequestUpdatePage />,
        },
      ],
    },
    {
      path: 'genset',
      children: [
        {
          index: true,
          element: <InstallationGENSETPage />,
        },
        {
          path: ':id',
          element: <InstallationGENSETDetailPage />,
        },
        {
          path: ':id/add-new',
          element: <InstallationGENSETCreateNewRequestPage />,
        },
        {
          path: ':id/:requestId',
          element: <InstallationGENSETRequestDetailPage />,
        },
        {
          path: ':id/:requestId/edit',
          element: <InstallationGENSETRequestUpdatePage />,
        },
      ],
    },
  ],
}

export default installationRoutes

import { SelectProps } from 'antd'
import Select from '../select'
import styles from './select-table-filter.module.css'

function FilterTableSelect({
  onChange,
  value,
  placeholder,
  options = [],
  style = {},
}: SelectProps & {
  onChange: (value: string) => void
}) {
  return (
    <Select
      className={styles.Select}
      placeholder={placeholder || 'Selec'}
      size="middle"
      allowClear
      defaultValue={value}
      onChange={(selectValue) => {
        onChange(selectValue)
      }}
      options={options}
      style={
        {
          '--ant-select-selector-bg': '#F1F4F9',
          '--ant-color-border': '#F1F4F9',
          ...style,
        } as never
      }
    />
  )
}

export default FilterTableSelect

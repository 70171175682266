import { Button, Flex, Space, TableProps, Tag } from 'antd'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { startCase } from 'lodash-es'
import { useDeliveryNotes } from '@/api/delivery-note'
import AddIcon from '@/assets/icons/add-icon'
import DeleteIcon from '@/assets/icons/delete-icon'
import RemoveRedEyeIcon from '@/assets/icons/remove-red-eye-icon'
import DataTable from '@/components/data-table'
import FilterTableSimpleInput from '@/components/table-filters/simple-input-table-filter'
import TitleSection from '@/components/title-section'
import useFilterSearchParams from '@/hooks/useFilterSearchParams'
import { AssetInsightDeliveryNote } from '@/types/delivery-note'
import FilterTableSelect from '@/components/table-filters/select-table-filter'
import EditIcon from '@/assets/icons/edit-icon'

function AssetInsightDeliveryNotePage() {
  const navigate = useNavigate()
  const [
    { page, pageSize, query, sort, order, searchParams },
    setSearchParams,
  ] = useFilterSearchParams()

  const condition = searchParams.get('condition') || null

  const { data, isFetching, isPlaceholderData, isPending } = useDeliveryNotes({
    page,
    pageSize,
    sorted: sort ? [{ sort, order }] : [{ sort: 'createdAt', order: 'DESC' }],
    filtered: [
      {
        id: 'contract.contractTitle',
        value: query || '',
        operator: 'global',
      },
      {
        id: 'tartStatus',
        value: condition === 'Delivery Note' ? '' : 'tar need action',
      },
    ],
  })
  const columns: TableProps<AssetInsightDeliveryNote>['columns'] = [
    {
      key: 'rowNumber',
      title: 'No',
      render: (_value, _record, index) => {
        return `${(page - 1) * pageSize + index + 1}.`
      },
    },
    {
      dataIndex: ['contract', 'contractTitle'],
      title: 'Contract Name',
      width: '15%',
      sorter: true,
    },
    {
      dataIndex: 'items',
      title: 'Total Item',
      width: 150,
      sorter: true,
      render(value) {
        return value?.length || 0
      },
    },
    {
      dataIndex: 'date',
      title: 'Movement Date',
      width: '15%',
      sorter: true,
      render(value) {
        return value ? dayjs(value).format('DD/MM/YYYY') : '-'
      },
    },
    {
      key: 'submittedBy',
      title: 'Submitted By',
      width: '15%',
      sorter: true,
      render(_value, record) {
        return record.sendBy.name === 'Other'
          ? record.otherSendByName
          : record.sendBy.name
      },
    },
    {
      dataIndex: 'tarStatus',
      title: 'TAR Status',
      width: 150,
      sorter: true,
      render: (value) => {
        let color = ''
        if (value === 'tar completed') color = 'success'
        if (value === 'tar need action') color = 'warning'
        if (value === 'tar rejected') color = 'error'
        return value ? <Tag color={color}>{startCase(value)}</Tag> : ''
      },
    },
    {
      dataIndex: 'status',
      title: 'Status',
      sorter: true,
      render: (value) => {
        let color = ''
        if (value === 'approved') color = 'success'
        if (value === 'pending') color = 'warning'
        if (value === 'rejected') color = 'error'
        return <Tag color={color}>{startCase(value)}</Tag>
      },
    },
    {
      dataIndex: 'id',
      title: 'Action',
      align: 'center',
      render(value, record) {
        return (
          <Space>
            <Button
              type="primary"
              size="middle"
              ghost
              icon={<RemoveRedEyeIcon width={20} height={20} />}
              onClick={() => navigate(`/asset-insight/delivery-note/${value}`)}
            />
            <Button
              type="primary"
              size="middle"
              icon={<EditIcon width={20} height={20} />}
              onClick={() =>
                navigate(`/asset-insight/delivery-note/${value}/edit`)
              }
            />
            <Button
              type="primary"
              size="middle"
              danger
              disabled={record.status !== 'pending'}
              icon={<DeleteIcon width={20} height={20} />}
              onClick={() => {}}
            />
          </Space>
        )
      },
    },
  ]

  return (
    <div>
      <TitleSection
        title="Delivery Note"
        fontWeight={800}
        actions={[
          <Button
            type="primary"
            key="add"
            icon={<AddIcon width={20} height={20} />}
            onClick={() => navigate('/asset-insight/delivery-note/add-new')}
          >
            Add New Request
          </Button>,
        ]}
      />
      <Flex vertical gap={24}>
        <Flex gap={16}>
          <FilterTableSimpleInput
            disabled={isFetching}
            onChange={(value) => {
              if (value) {
                searchParams.set('query', value)
              } else {
                searchParams.delete('query')
              }
              setSearchParams(searchParams)
            }}
            value={query}
          />
          <FilterTableSelect
            placeholder="Select Condition"
            options={[
              { label: 'Delivery Note', value: 'Delivery Note' },
              {
                label: 'Delivery Note with Transfer Asset Request (TAR)',
                value: 'Delivery Note with Transfer Asset Request (TAR)',
              },
            ]}
            value={condition}
            onChange={(value) => {
              if (value) {
                searchParams.set('condition', value)
              } else {
                searchParams.delete('')
              }
              setSearchParams(searchParams)
            }}
          />
        </Flex>

        <DataTable<AssetInsightDeliveryNote>
          rowSelectable={false}
          loading={isPlaceholderData || isPending}
          dataSource={data?.data || []}
          columns={columns}
          total={data?.total}
        />
      </Flex>
    </div>
  )
}

export default AssetInsightDeliveryNotePage

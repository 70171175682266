import React from 'react'

const ResetWrenchIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16.3 18.8 14.1 21 12 18.9l2.2-2.2a3.2 3.2 0 0 1-.2-1.2c0-1 .3-1.8 1-2.5a3.4 3.4 0 0 1 3.4-.9c.3 0 .5.2.8.3l-2.4 2.4 1.4 1.4 2.4-2.3a3.8 3.8 0 0 1 .4 1.6c0 1-.3 1.8-1 2.5a3.4 3.4 0 0 1-3.1 1l-.6-.2Zm4.5-8.8h-2.1A6.9 6.9 0 0 0 12 5c-2 0-3.6.7-5 2-1.3 1.4-2 3-2 5a6.8 6.8 0 0 0 3 5.8V15h2v6H4v-2h2.3A9 9 0 0 1 3 12a8.7 8.7 0 0 1 2.6-6.4A9.1 9.1 0 0 1 12 3c2.2 0 4 .7 5.7 2 1.6 1.3 2.6 3 3 5Z"
      />
    </svg>
  )
}

export default ResetWrenchIcon

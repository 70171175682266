import React from 'react'

const TroubleshootIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="m20.6 21-4.7-4.7A8.2 8.2 0 0 1 11 18c-1.5 0-2.9-.4-4-1.1A8.2 8.2 0 0 1 4 14h2.5c.6.6 1.3 1.1 2 1.5.8.3 1.6.5 2.5.5 1.7 0 3-.6 4.3-1.8A5.8 5.8 0 0 0 17 10c0-1.7-.6-3-1.8-4.3A5.8 5.8 0 0 0 11 4c-1.6 0-3 .5-4 1.6a5.8 5.8 0 0 0-2 3.9H3c.2-2.1 1-3.9 2.5-5.3A7.7 7.7 0 0 1 11 2c2.2 0 4.1.8 5.7 2.3A7.7 7.7 0 0 1 19 10a7.7 7.7 0 0 1-1.7 4.9l4.7 4.7-1.4 1.4ZM9.9 14 8.3 8.8 7 12.5H2V11h4l1.7-4.8h1.5l1.5 5.1 1-3.3h1.6l1.4 3h.8v1.5h-1.7l-1.2-2.3-1.2 3.8H9.9Z"
      />
    </svg>
  )
}

export default TroubleshootIcon

import { keepPreviousData, queryOptions, useQuery } from '@tanstack/react-query'
import api from '@/lib/axios'
import {
  APIResponse,
  APIResponseWithoutTotal,
  RequestParams,
} from '@/types/general'
import { AssetInsightTracking } from '@/types/tracking'

const fetchTrackings = async (filters: RequestParams) => {
  const response = await api.get('/asset-insight/tracking', {
    params: filters,
  })
  return response.data as APIResponse<AssetInsightTracking[]>
}
const fetchTracking = async (id: string | undefined) => {
  const response = await api.get(`/asset-insight/tracking/${id}`)
  return response.data as APIResponseWithoutTotal<AssetInsightTracking>
}

const queries = {
  all: () => ['trackings'],
  lists: () => [...queries.all(), 'list'],
  list: (filters: RequestParams) =>
    queryOptions({
      queryKey: [...queries.lists(), filters],
      queryFn: () => fetchTrackings(filters),
      placeholderData: keepPreviousData,
    }),
  details: () => [...queries.all(), 'detail'],
  detail: (id: string | undefined) =>
    queryOptions({
      queryKey: [...queries.details(), id],
      queryFn: () => fetchTracking(id),
      enabled: Boolean(id),
      staleTime: 5000,
    }),
}

export const useTrackings = (filter: RequestParams) => {
  return useQuery(queries.list(filter))
}
export const useTracking = (id: string | undefined) => {
  return useQuery(queries.detail(id))
}

import {
  keepPreviousData,
  queryOptions,
  useMutation as useReactQueryMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import api from '@/lib/axios'
import {
  APIResponse,
  APIResponseWithoutTotal,
  RequestParams,
} from '@/types/general'
import {
  CreateAssetInsightMutationDto,
  AssetInsightMutation,
} from '@/types/mutation'

const fetchMutations = async (filters: RequestParams) => {
  const response = await api.get('/asset-insight/mutation', {
    params: filters,
  })
  return response.data as APIResponse<AssetInsightMutation[]>
}
const fetchMutation = async (id: string | undefined) => {
  const response = await api.get(`/asset-insight/mutation/${id}`)
  return response.data as APIResponseWithoutTotal<AssetInsightMutation>
}

const createMutation = async (payload: CreateAssetInsightMutationDto) => {
  const response = await api.post('/asset-insight/mutation', payload)
  return response.data as AssetInsightMutation
}
const updateMutation = async (
  id: string | undefined,
  payload: CreateAssetInsightMutationDto
) => {
  const response = await api.put(`/asset-insight/mutation/${id}`, payload)
  return response.data as AssetInsightMutation
}
const deleteMutation = async (id: string | string[]) => {
  const ids = typeof id === 'string' ? [id] : id
  return api.post(`/asset-insight/mutation/multiple/force-delete`, { ids })
}

const queires = {
  all: () => ['write offs'],
  lists: () => [...queires.all(), 'list'],
  list: (filters: RequestParams) =>
    queryOptions({
      queryKey: [...queires.lists(), filters],
      queryFn: () => fetchMutations(filters),
      placeholderData: keepPreviousData,
    }),
  details: () => [...queires.all(), 'detail'],
  detail: (id: string | undefined) =>
    queryOptions({
      queryKey: [...queires.details(), id],
      queryFn: () => fetchMutation(id),
      enabled: Boolean(id),
      staleTime: 5000,
    }),
}

export const useMutations = (filter: RequestParams) => {
  return useQuery(queires.list(filter))
}
export const useMutation = (id: string | undefined) => {
  return useQuery(queires.detail(id))
}
export const useCreateMutation = () => {
  const queryClient = useQueryClient()
  return useReactQueryMutation({
    mutationFn: (payload: CreateAssetInsightMutationDto) =>
      createMutation(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queires.lists(),
      })
    },
  })
}
export const useUpdateMutation = (id: string | undefined) => {
  const queryClient = useQueryClient()
  return useReactQueryMutation({
    mutationFn: (payload: CreateAssetInsightMutationDto) =>
      updateMutation(id, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queires.lists(),
      })
    },
  })
}
export const useDeleteMutation = () => {
  const queryClient = useQueryClient()
  return useReactQueryMutation({
    mutationFn: (id: string | string[]) => deleteMutation(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queires.lists(),
      })
    },
  })
}

import { Button, Flex, TableProps, Tag } from 'antd'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useTrackings } from '@/api/tracking'
import FileDownloadIcon from '@/assets/icons/file-download-icon'
import RemoveRedEyeIcon from '@/assets/icons/remove-red-eye-icon'
import DataTable from '@/components/data-table'
import FilterTableSimpleInput from '@/components/table-filters/simple-input-table-filter'
import TitleSection from '@/components/title-section'
import useFilterSearchParams from '@/hooks/useFilterSearchParams'
import { AssetInsightTracking } from '@/types/tracking'

function AssetInsightTrackingPage() {
  const navigate = useNavigate()
  const [
    { page, pageSize, query, sort, order, searchParams },
    setSearchParams,
  ] = useFilterSearchParams()

  const { data, isFetching, isPlaceholderData, isPending } = useTrackings({
    page,
    pageSize,
    sorted: sort ? [{ sort, order }] : [{ sort: 'createdAt', order: 'DESC' }],
    filtered: [
      {
        id: 'contract.contractTitle,contract.jobType,location,manager.name',
        value: query || '',
        operator: 'global',
      },
    ],
  })
  const columns: TableProps<AssetInsightTracking>['columns'] = [
    {
      key: 'rowNumber',
      title: 'No',
      render: (_value, _record, index) => {
        return `${(page - 1) * pageSize + index + 1}.`
      },
    },
    {
      dataIndex: ['contract', 'contractTitle'],
      title: 'Contract Name',
      sorter: true,
    },
    {
      dataIndex: ['contract', 'jobType'],
      title: 'Job Type',
      sorter: true,
    },
    {
      dataIndex: 'startDate',
      title: 'Start Date',
      sorter: true,
      render(value) {
        return value ? dayjs(value).format('DD-MMM-YYYY') : '-'
      },
    },
    {
      dataIndex: 'endDate',
      title: 'End Date',
      sorter: true,
      render(value) {
        return value ? dayjs(value).format('DD-MMM-YYYY') : '-'
      },
    },
    {
      dataIndex: 'location',
      title: 'Location',
      sorter: true,
    },
    {
      dataIndex: ['manager', 'name'],
      title: 'Manager',
      sorter: true,
    },
    {
      dataIndex: 'active',
      title: 'Status',
      sorter: true,
      render: (value) => {
        return (
          <Tag color={value ? 'success' : 'error'}>
            {value ? 'Active' : 'Non Active'}
          </Tag>
        )
      },
    },
    {
      dataIndex: 'id',
      title: 'Action',
      render(value) {
        return (
          <Button
            type="primary"
            size="middle"
            icon={<RemoveRedEyeIcon width={20} height={20} />}
            onClick={() => navigate(`/asset-insight/tracking/${value}`)}
          />
        )
      },
    },
  ]

  return (
    <div>
      <TitleSection
        title="Tracking"
        fontWeight={800}
        actions={[
          <Button
            type="primary"
            key="download"
            ghost
            onClick={() => {}}
            icon={<FileDownloadIcon width={20} height={20} />}
          >
            Download
          </Button>,
        ]}
      />
      <Flex vertical gap={24}>
        <FilterTableSimpleInput
          disabled={isFetching}
          onChange={(value) => {
            if (value) {
              searchParams.set('query', value)
            } else {
              searchParams.delete('query')
            }
            setSearchParams(searchParams)
          }}
          value={query}
        />

        <DataTable<AssetInsightTracking>
          rowSelectable={false}
          loading={isPlaceholderData || isPending}
          dataSource={data?.data || []}
          columns={columns}
          total={data?.total}
        />
      </Flex>
    </div>
  )
}

export default AssetInsightTrackingPage

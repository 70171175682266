import React from 'react'

// { ...props }: React.SVGProps<SVGSVGElement>
function AddIcon({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path fill="currentColor" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2Z" />
    </svg>
  )
}

export default AddIcon

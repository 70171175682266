import { lazy } from 'react'

const ErrorElementRoute = lazy(
  () => import('@/components/exception/error-element-route')
)

const ManagementUserPage = lazy(() => import('@/pages/management/user'))
const ManagemenetUserCreatePage = lazy(
  () => import('@/pages/management/user/create')
)
const ManagementUserDetailPage = lazy(
  () => import('@/pages/management/user/detail')
)
const ManagementUserUpdatePage = lazy(
  () => import('@/pages/management/user/update')
)
const ManagementRolePage = lazy(() => import('@/pages/management/role'))
const ManagemenetRoleCreatePage = lazy(
  () => import('@/pages/management/role/create')
)
const ManagementRoleDetailPage = lazy(
  () => import('@/pages/management/role/detail')
)
const ManagementRoleUpdatePage = lazy(
  () => import('@/pages/management/role/update')
)

const managementRoutes = {
  path: 'management',
  errorElement: <ErrorElementRoute />,
  children: [
    {
      path: 'user',
      children: [
        {
          index: true,
          element: <ManagementUserPage />,
        },
        {
          path: 'add-new',
          element: <ManagemenetUserCreatePage />,
        },
        {
          path: ':id',
          element: <ManagementUserDetailPage />,
        },
        {
          path: ':id/edit',
          element: <ManagementUserUpdatePage />,
        },
      ],
    },
    {
      path: 'role',
      children: [
        {
          index: true,
          element: <ManagementRolePage />,
        },
        {
          path: 'add-new',
          element: <ManagemenetRoleCreatePage />,
        },
        {
          path: ':id',
          element: <ManagementRoleDetailPage />,
        },
        {
          path: ':id/edit',
          element: <ManagementRoleUpdatePage />,
        },
      ],
    },
  ],
}

export default managementRoutes

import { Suspense, lazy } from 'react'
import AssetInsightTrackingPage from '@/pages/asset-insight/tracking'
import AssetInsightWriteOffPage from '@/pages/asset-insight/write-off'
import AssetInsightMutationPage from '@/pages/asset-insight/mutation'
import AssetInsightDeliveryNotePage from '@/pages/asset-insight/delivery-note'

const ErrorElementRoute = lazy(
  () => import('@/components/exception/error-element-route')
)

const AssetInsightDeliveryNoteDetailPage = lazy(
  () => import('@/pages/asset-insight/delivery-note/detail')
)
const AssetInsightDeliveryNoteUpdatePage = lazy(
  () => import('@/pages/asset-insight/delivery-note/update')
)

const AssetInsightDeliveryNoteCreatePage = lazy(
  () => import('@/pages/asset-insight/delivery-note/create')
)
const WrapperAssetInsightPage = lazy(
  () => import('@/pages/asset-insight/partials/wrapper-asset-insight-page')
)
const AssetInsightDetailTrackingPage = lazy(
  () => import('@/pages/asset-insight/tracking/detail')
)

const AssetInsightWriteOffCreatePage = lazy(
  () => import('@/pages/asset-insight/write-off/create')
)
const UnderDevelopmentPage = lazy(() => import('@/pages/under-development'))
const AssetInsightMutationCreatePage = lazy(
  () => import('@/pages/asset-insight/mutation/create')
)

const AssetInsightDetailMutationPage = lazy(
  () => import('@/pages/asset-insight/mutation/detail')
)
const AssetInsightUpdateMutationPage = lazy(
  () => import('@/pages/asset-insight/mutation/update')
)
const assetInsightRoutes = {
  path: 'asset-insight',
  errorElement: <ErrorElementRoute />,
  element: (
    <Suspense fallback="">
      <WrapperAssetInsightPage />
    </Suspense>
  ),
  children: [
    {
      path: 'tracking',
      children: [
        {
          index: true,
          element: <AssetInsightTrackingPage />,
        },
        {
          path: ':id',
          element: (
            <Suspense fallback="">
              <AssetInsightDetailTrackingPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'write-off',
      children: [
        {
          index: true,
          element: <AssetInsightWriteOffPage />,
        },
        {
          path: 'add-new',
          element: (
            <Suspense fallback="">
              <AssetInsightWriteOffCreatePage />
            </Suspense>
          ),
        },
        {
          path: ':id',
          element: (
            <Suspense fallback="">
              <UnderDevelopmentPage />
            </Suspense>
          ),
        },
        {
          path: ':id/edit',
          element: (
            <Suspense fallback="">
              <UnderDevelopmentPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'mutation',
      children: [
        {
          index: true,
          element: <AssetInsightMutationPage />,
        },
        {
          path: 'add-new',
          element: (
            <Suspense fallback="">
              <AssetInsightMutationCreatePage />
            </Suspense>
          ),
        },
        {
          path: ':id',
          element: (
            <Suspense fallback="">
              <AssetInsightDetailMutationPage />
            </Suspense>
          ),
        },
        {
          path: ':id/edit',
          element: (
            <Suspense fallback="">
              <AssetInsightUpdateMutationPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'delivery-note',
      children: [
        {
          index: true,
          element: <AssetInsightDeliveryNotePage />,
        },
        {
          path: 'add-new',
          element: (
            <Suspense fallback="">
              <AssetInsightDeliveryNoteCreatePage />
            </Suspense>
          ),
        },
        {
          path: ':id',
          element: (
            <Suspense fallback="">
              <AssetInsightDeliveryNoteDetailPage />
            </Suspense>
          ),
        },
        {
          path: ':id/edit',
          element: (
            <Suspense fallback="">
              <AssetInsightDeliveryNoteUpdatePage />
            </Suspense>
          ),
        },
      ],
    },
  ],
}

export default assetInsightRoutes

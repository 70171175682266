import { Link } from 'react-router-dom'
import BuildIcon from '@/assets/icons/build-icon'
import DashboardIcon from '@/assets/icons/dashboard-icon'
import DatasetIcon from '@/assets/icons/dataset-icon'
import InkHighlighterMoveIcon from '@/assets/icons/ink-highlighter-move-icon'
import ManageAccountsIcon from '@/assets/icons/manage-accounts-icon'
import ResetWrenchIcon from '@/assets/icons/reset-wrench-icon'
import TrackChangesIcon from '@/assets/icons/track-changes-icon'
import TroubleshootIcon from '@/assets/icons/troubleshoot-icon'

const useMenu = () => {
  return [
    {
      key: 'dashboard',
      icon: <DashboardIcon width={20} height={20} />,
      label: 'Dashboard',
    },
    {
      key: '/installation',
      icon: <BuildIcon width={20} height={20} />,
      label: 'Installation',
      children: [
        {
          key: '/installation/esp',
          label: <Link to="/installation/esp">ESP</Link>,
        },
        {
          key: '/installation/hps',
          label: <Link to="/installation/hps">HPS</Link>,
        },
        {
          key: '/installation/hpu',
          label: <Link to="/installation/hpu">HPU</Link>,
        },
        {
          key: '/installation/lesp',
          label: <Link to="/installation/lesp">LESP</Link>,
        },
        {
          key: '/installation/genset',
          label: <Link to="/installation/genset">GENSET</Link>,
        },
      ],
    },
    {
      key: '/troubleshoot',
      icon: <TroubleshootIcon width={20} height={20} />,
      label: 'Troubleshoot',
      children: [
        {
          key: '/troubleshoot/esp',
          label: <Link to="/troubleshoot/esp">ESP</Link>,
        },
        {
          key: '/troubleshoot/hps',
          label: <Link to="/troubleshoot/hps">HPS</Link>,
        },
        {
          key: '/troubleshoot/hpu',
          label: <Link to="/troubleshoot/hpu">HPU</Link>,
        },
        {
          key: '/troubleshoot/lesp',
          label: <Link to="/troubleshoot/lesp">LESP</Link>,
        },
        {
          key: '/troubleshoot/genset',
          label: <Link to="/troubleshoot/genset">GENSET</Link>,
        },
      ],
    },
    {
      key: '/pulling',
      icon: <InkHighlighterMoveIcon width={20} height={20} />,
      label: 'Pulling / Dismantle',
      children: [
        {
          key: '/pulling/esp',
          label: <Link to="/pulling/esp">ESP</Link>,
        },
        {
          key: '/pulling/hps',
          label: <Link to="/pulling/hps">HPS</Link>,
        },
        {
          key: '/pulling/hpu',
          label: <Link to="/pulling/hpu">HPU</Link>,
        },
        {
          key: '/pulling/lesp',
          label: <Link to="/pulling/lesp">LESP</Link>,
        },
        {
          key: '/pulling/genset',
          label: <Link to="/pulling/genset">GENSET</Link>,
        },
      ],
    },
    {
      key: '/asset-insight',
      icon: <TrackChangesIcon width={20} height={20} />,
      label: <Link to="/asset-insight/tracking">Asset Insight</Link>,
    },
    {
      key: '/pm-and-pdm',
      icon: <ResetWrenchIcon width={20} height={20} />,
      label: <Link to="/pm-and-pdm/pm">PM and PdM</Link>,
    },
    {
      key: '/management',
      icon: <ManageAccountsIcon width={20} height={20} />,
      label: 'Management',
      children: [
        {
          key: '/management/user',
          label: <Link to="/management/user">User</Link>,
        },
        {
          key: '/management/role',
          label: <Link to="/management/role">Role</Link>,
        },
      ],
    },
    {
      key: '/master-data',
      icon: <DatasetIcon width={20} height={20} />,
      label: 'Master Data',
      children: [
        {
          key: '/master-data/material',
          label: <Link to="/master-data/material">Material</Link>,
        },
        {
          key: '/master-data/customer',
          label: <Link to="/master-data/customer">Customer</Link>,
        },
        {
          key: '/master-data/asset',
          label: <Link to="/master-data/asset">Asset</Link>,
        },
        {
          key: '/master-data/contract',
          label: <Link to="/master-data/contract">Contract</Link>,
        },
        {
          key: '/master-data/manpower',
          label: <Link to="/master-data/manpower">Manpower</Link>,
        },
        {
          key: '/master-data/sop',
          label: <Link to="/master-data/sop">SOP</Link>,
        },
        {
          key: '/master-data/tool',
          label: <Link to="/master-data/tool">Tool</Link>,
        },
        {
          key: '/master-data/position',
          label: <Link to="/master-data/position">Position</Link>,
        },
      ],
    },
  ]
}

export default useMenu

import { ModalFuncProps } from 'antd'
import CloseIcon from '@/assets/icons/close-icon'
import { cn } from '@/lib/utils'
import { modal } from '../antd-global-app'
import styles from './popup.module.css'

export function DeleteConfirmIllustration({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 126 120"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <rect width="120" height="120" x="5.5" fill="#FCEBEA" rx="60" />
      <path
        fill="#EFA1A1"
        d="M77.7 53.8c-.1 0-.2-2.9-.2-6.4 0-3.5 0-6.4.2-6.4.1 0 .2 2.9.2 6.4 0 3.5 0 6.4-.2 6.4ZM81.4 55.1c-.1 0-.2-2.6-.2-5.7 0-3.2.1-5.8.2-5.8s.2 2.6.2 5.8c0 3.1 0 5.7-.2 5.7ZM51.8 60c-.2 0-.3-2.8-.3-6.2 0-3.4.1-6.1.3-6.1.1 0 .2 2.7.2 6.1 0 3.4-.1 6.2-.2 6.2ZM56 54.2s-.2-2-.2-4.6c0-2.5.1-4.6.3-4.6.1 0 .2 2 .2 4.6 0 2.5 0 4.6-.2 4.6Z"
      />
      <path
        fill="#DFDFE3"
        d="M41.4 47.6 33.6 53c-.5.4-1.2.3-1.6-.3L24.5 42c-.3-.5-.2-1.3.3-1.7l5-3.4 6.5 1.3 5.4 7.8c.4.5.2 1.3-.3 1.6Z"
      />
      <path fill="#B4B4BC" d="m29.8 37 2 3c.4.4 1 .6 1.6.2l2.9-2-6.5-1.3Z" />
      <path
        fill="#FAFAFA"
        d="m31.1 40.3-1.7 1.3a14 14 0 0 1-1.9 1.2l1.8-1.3a14 14 0 0 1 1.8-1.2ZM34.6 40.4ZM36.1 41.4ZM37 42.8ZM38 44.1ZM39 45.5Z"
      />
      <path
        fill="#DFDFE3"
        d="m86.2 45.2 6.5 1.3c.5.1 1-.2 1-.6l1.8-9c.1-.5-.2-1-.6-1l-4.2-.9-3.8 2.7-1.4 6.5c0 .5.2 1 .7 1Z"
      />
      <path
        fill="#B4B4BC"
        d="m90.7 35-.5 2.6c0 .4-.5.6-.9.6l-2.4-.5 3.8-2.7Z"
      />
      <path
        fill="#FAFAFA"
        d="m90.8 37.6 1.5.3 1.5.3-1.5-.4a10 10 0 0 0-1.5-.2ZM88.5 38.6ZM87.8 39.7ZM87.6 40.9ZM87.4 42ZM87.2 43.1Z"
      />
      <path
        fill="#E87676"
        d="m70.8 45-18-4.4a1.6 1.6 0 0 1-1.3-2l6-24.4c.3-.8 1.2-1.4 2-1.2l12.7 3.2 5.3 8.5-4.7 19.1c-.2.9-1.1 1.4-2 1.2Z"
      />
      <path
        fill="#E87676"
        d="M72.2 16.1 71 21c-.3 1.2.4 2.4 1.6 2.7l4.9 1.1-5.3-8.6Z"
      />
      <path
        fill="#fff"
        d="M69.3 39.5v-.2l.2-.6.5-2.5 2.1-8.5-12.2-3s.2-.1.1 0v.4l-.2.4-.2.8-.4 1.6-.7 3-1.4 5.6-.1-.1c3.6 1 6.7 1.7 8.9 2.2l2.5.7c-.6 0-1.4-.3-2.5-.5l-9-2.2v-.1l1.3-5.6.8-3 .4-1.7.2-.8V25l.1-.2v-.1a115191.6 115191.6 0 0 1 12.5 3h.1l-2.2 8.6-.6 2.4a882 882 0 0 1-.2.8ZM63 38.1ZM70 36.6ZM70.8 33.6ZM71.5 30.6Z"
      />
      <path
        fill="#fff"
        d="M72.2 16.1 71 21c-.3 1.2.4 2.4 1.6 2.7l4.9 1.1-5.3-8.6Z"
        opacity=".4"
      />
      <path fill="#E45C5C" d="M95.2 62H36.1v38.4h59.1V62Z" />
      <path fill="#000" d="M95.2 62H79.6v38.4h15.6V62Z" opacity=".3" />
      <path fill="#E45C5C" d="m79.6 62 9 10.5h14.7l-8-10.5H79.5Z" />
      <path fill="#E45C5C" d="M36 62 24 72.5h45.9L79.6 62H36.1Z" />
      <path
        fill="#263238"
        d="m79.6 62-.2.2-.5.6a134319 134319 0 0 0-9 9.8H23.5h.2a181.7 181.7 0 0 1 8.7-.1h37.5l7-7.6a8756.6 8756.6 0 0 0 2.7-2.9ZM79.6 100.4Z"
      />
      <path fill="#fff" d="M59.5 94.8h-6.3v5.6h6.3v-5.6Z" opacity=".3" />
      <path
        fill="#000"
        d="M36 72.6v2.9l34.6-.2 8.8-13.1L70 72.5H36.1Z"
        opacity=".3"
      />
      <path
        fill="#D33"
        d="M4 77.2A2 2 0 1 1 1.1 80a2 2 0 0 1 3-3ZM120.5 13.2a2 2 0 1 1-4.1 0 2 2 0 0 1 4.1 0Z"
      />
    </svg>
  )
}
export const showDeleteConfirm = ({
  title = 'Delete Confirmation',
  okText = 'Confirm',
  cancelText = 'Cancel',
  className,
  content = (
    <span
      style={{
        fontSize: 14,
        fontWeight: 500,
        color: '#5E5E5E',
        textAlign: 'center',
        display: 'inline-block',
        lineHeight: '20px',
      }}
    >
      Are you sure you want to delete this data? This action cannot be undone
      and <span style={{ fontWeight: 600 }}>will permanently delete data</span>{' '}
      from the system.
    </span>
  ),
  ...props
}: ModalFuncProps) => {
  modal.confirm({
    className: cn(styles.Popup, className),
    title,
    icon: null,
    closable: true,
    closeIcon: <CloseIcon width={20} height={20} />,
    okText,
    okType: 'primary',
    cancelText,
    styles: {
      content: { minWidth: 420, padding: '64px 32px 32px', borderRadius: 12 },
    },
    cancelButtonProps: {
      block: true,
      size: 'middle',
      style: { boxShadow: 'none' },
    },
    okButtonProps: {
      size: 'middle',
      block: true,
      danger: true,
    },
    centered: true,
    ...props,
    content: (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 24,
          }}
        >
          <DeleteConfirmIllustration width={125} height={120} />
        </div>
        {content}
      </div>
    ),
  })
}

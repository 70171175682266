import React from 'react'

const DatasetIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M7 17h4v-4H7v4Zm6 0h4v-4h-4v4Zm-6-6h4V7H7v4Zm6 0h4V7h-4v4ZM5 21a2 2 0 0 1-1.4-.6A2 2 0 0 1 3 19V5c0-.5.2-1 .6-1.4A2 2 0 0 1 5 3h14c.6 0 1 .2 1.4.6.4.4.6.9.6 1.4v14c0 .6-.2 1-.6 1.4a2 2 0 0 1-1.4.6H5Zm0-2h14V5H5v14Z"
      />
    </svg>
  )
}

export default DatasetIcon

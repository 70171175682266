import { Select as AntdSelect, SelectProps, Spin } from 'antd'
import KeyboardArrowDownIcon from '@/assets/icons/keyboard-arrow-down-icon'
import { cn } from '@/lib/utils'
import styles from './select.module.css'

function Select({ className, ...props }: SelectProps) {
  return (
    <AntdSelect
      className={cn(styles.Select, className)}
      suffixIcon={
        props.loading ? (
          <Spin size="small" />
        ) : (
          <KeyboardArrowDownIcon width={20} height={20} />
        )
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

export default Select

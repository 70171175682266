import axios, { InternalAxiosRequestConfig } from 'axios'

const api = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_API_URL,
  withCredentials: true,
})

// Request interceptor for API calls
api.interceptors.request.use(
  (config) => {
    const { params } = config
    if (config.params?.filtered) {
      params.filtered = JSON.stringify(config.params.filtered)
    }
    if (config.params?.sorted) {
      params.sorted = JSON.stringify(config.params.sorted)
    }
    const overrideConfig = {
      ...config,
      params,
      signal: AbortSignal.timeout(30000),
    } satisfies InternalAxiosRequestConfig

    return overrideConfig
  },
  (error) => {
    Promise.reject(error)
  }
)

// Response interceptor for API calls
api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error?.response?.status === 403) {
      return null
    }
    return Promise.reject(error)
  }
)

export default api

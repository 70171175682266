import { lazy } from 'react'

const ErrorElementRoute = lazy(
  () => import('@/components/exception/error-element-route')
)

const MasterDataAssetPage = lazy(() => import('@/pages/master-data/asset'))
const MasterDataCreateAssetPage = lazy(
  () => import('@/pages/master-data/asset/create')
)
const MasterDataDetailAssetPage = lazy(
  () => import('@/pages/master-data/asset/detail')
)
const MasterDataUpdateAssetPage = lazy(
  () => import('@/pages/master-data/asset/update')
)
const MasterDataContractPage = lazy(
  () => import('@/pages/master-data/contract')
)
const MasterDataCreateContractPage = lazy(
  () => import('@/pages/master-data/contract/create')
)
const MasterDataDetailContractPage = lazy(
  () => import('@/pages/master-data/contract/detail')
)
const MasterDataUpdateContractPage = lazy(
  () => import('@/pages/master-data/contract/update')
)
const MasterDataCustomerPage = lazy(
  () => import('@/pages/master-data/customer')
)
const MasterDataCreateCustomerPage = lazy(
  () => import('@/pages/master-data/customer/create')
)
const MasterDataDetailCustomerPage = lazy(
  () => import('@/pages/master-data/customer/detail')
)
const MasterDataUpdateCustomerPage = lazy(
  () => import('@/pages/master-data/customer/update')
)
const MasterDataManpowerPage = lazy(
  () => import('@/pages/master-data/manpower')
)
const MasterDataCreateManpowerPage = lazy(
  () => import('@/pages/master-data/manpower/create')
)
const MasterDataDetailManpowerPage = lazy(
  () => import('@/pages/master-data/manpower/detail')
)
const MasterDataUpdateManpowerPage = lazy(
  () => import('@/pages/master-data/manpower/update')
)
const MasterDataMaterialPage = lazy(
  () => import('@/pages/master-data/material')
)
const MasterDataCreateMaterialPage = lazy(
  () => import('@/pages/master-data/material/create')
)
const MasterDataDetailMaterialPage = lazy(
  () => import('@/pages/master-data/material/detail')
)
const MasterDataUpdateMaterialPage = lazy(
  () => import('@/pages/master-data/material/update')
)
const MasterDataPositionPage = lazy(
  () => import('@/pages/master-data/position')
)
const MasterDataCreatePositionPage = lazy(
  () => import('@/pages/master-data/position/create')
)
const MasterDataDetailPositionPage = lazy(
  () => import('@/pages/master-data/position/detail')
)
const MasterDataUpdatePositionPage = lazy(
  () => import('@/pages/master-data/position/update')
)
const MasterDataSOPPage = lazy(() => import('@/pages/master-data/sop'))
const MasterDataCreateSOPPage = lazy(
  () => import('@/pages/master-data/sop/create')
)
const MasterDataDetailSOPPage = lazy(
  () => import('@/pages/master-data/sop/detail')
)
const MasterDataUpdateSOPPage = lazy(
  () => import('@/pages/master-data/sop/update')
)
const MasterDataToolPage = lazy(() => import('@/pages/master-data/tool'))
const MasterDataCreateToolPage = lazy(
  () => import('@/pages/master-data/tool/create')
)
const MasterDataDetailToolPage = lazy(
  () => import('@/pages/master-data/tool/detail')
)
const MasterDataUpdateToolPage = lazy(
  () => import('@/pages/master-data/tool/update')
)

const masterDataRoutes = {
  path: 'master-data',
  errorElement: <ErrorElementRoute />,
  children: [
    {
      path: 'material',
      children: [
        {
          index: true,
          element: <MasterDataMaterialPage />,
        },
        {
          path: 'add-new',
          element: <MasterDataCreateMaterialPage />,
        },
        {
          path: ':id',
          element: <MasterDataDetailMaterialPage />,
        },
        {
          path: ':id/edit',
          element: <MasterDataUpdateMaterialPage />,
        },
      ],
    },
    {
      path: 'customer',
      children: [
        {
          index: true,
          element: <MasterDataCustomerPage />,
        },
        {
          path: 'add-new',
          element: <MasterDataCreateCustomerPage />,
        },
        {
          path: ':id',
          element: <MasterDataDetailCustomerPage />,
        },
        {
          path: ':id/edit',
          element: <MasterDataUpdateCustomerPage />,
        },
      ],
    },
    {
      path: 'asset',
      children: [
        {
          index: true,
          element: <MasterDataAssetPage />,
        },
        {
          path: 'add-new',
          element: <MasterDataCreateAssetPage />,
        },
        {
          path: ':id',
          element: <MasterDataDetailAssetPage />,
        },
        {
          path: ':id/edit',
          element: <MasterDataUpdateAssetPage />,
        },
      ],
    },
    {
      path: 'contract',
      children: [
        {
          index: true,
          element: <MasterDataContractPage />,
        },
        {
          path: 'add-new',
          element: <MasterDataCreateContractPage />,
        },
        {
          path: ':id',
          element: <MasterDataDetailContractPage />,
        },
        {
          path: ':id/edit',
          element: <MasterDataUpdateContractPage />,
        },
      ],
    },
    {
      path: 'manpower',
      children: [
        {
          index: true,
          element: <MasterDataManpowerPage />,
        },
        {
          path: 'add-new',
          element: <MasterDataCreateManpowerPage />,
        },
        {
          path: ':id',
          element: <MasterDataDetailManpowerPage />,
        },
        {
          path: ':id/edit',
          element: <MasterDataUpdateManpowerPage />,
        },
      ],
    },
    {
      path: 'sop',
      children: [
        {
          index: true,
          element: <MasterDataSOPPage />,
        },
        {
          path: 'add-new',
          element: <MasterDataCreateSOPPage />,
        },
        {
          path: ':id',
          element: <MasterDataDetailSOPPage />,
        },
        {
          path: ':id/edit',
          element: <MasterDataUpdateSOPPage />,
        },
      ],
    },
    {
      path: 'tool',
      children: [
        {
          index: true,
          element: <MasterDataToolPage />,
        },
        {
          path: 'add-new',
          element: <MasterDataCreateToolPage />,
        },
        {
          path: ':id',
          element: <MasterDataDetailToolPage />,
        },
        {
          path: ':id/edit',
          element: <MasterDataUpdateToolPage />,
        },
      ],
    },
    {
      path: 'position',
      children: [
        {
          index: true,
          element: <MasterDataPositionPage />,
        },
        {
          path: 'add-new',
          element: <MasterDataCreatePositionPage />,
        },
        {
          path: ':id',
          element: <MasterDataDetailPositionPage />,
        },
        {
          path: ':id/edit',
          element: <MasterDataUpdatePositionPage />,
        },
      ],
    },
  ],
}

export default masterDataRoutes

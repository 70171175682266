import { Button, Flex, Space, TableProps, Tag } from 'antd'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useMutations } from '@/api/mutation'
import AddIcon from '@/assets/icons/add-icon'
import DeleteIcon from '@/assets/icons/delete-icon'
import RemoveRedEyeIcon from '@/assets/icons/remove-red-eye-icon'
import DataTable from '@/components/data-table'
import FilterTableSimpleInput from '@/components/table-filters/simple-input-table-filter'
import TitleSection from '@/components/title-section'
import useFilterSearchParams from '@/hooks/useFilterSearchParams'
import { AssetInsightMutation } from '@/types/mutation'

function AssetInsightMutationPage() {
  const navigate = useNavigate()
  const [
    { page, pageSize, query, sort, order, searchParams },
    setSearchParams,
  ] = useFilterSearchParams()

  const { data, isFetching, isPlaceholderData, isPending } = useMutations({
    page,
    pageSize,
    sorted: sort ? [{ sort, order }] : [{ sort: 'createdAt', order: 'DESC' }],
    filtered: [
      {
        id: 'contract.contractTitle',
        value: query || '',
        operator: 'global',
      },
    ],
  })
  const columns: TableProps<AssetInsightMutation>['columns'] = [
    {
      key: 'rowNumber',
      title: 'No',
      render: (_value, _record, index) => {
        return `${(page - 1) * pageSize + index + 1}.`
      },
    },
    {
      dataIndex: ['contract', 'contractTitle'],
      title: 'Contract Name',
      sorter: true,
    },
    { dataIndex: 'tarNumber', title: 'TAR Number', sorter: true },
    {
      dataIndex: 'items',
      title: 'Total Asset',
      sorter: true,
      render(value) {
        return value.length
      },
    },
    {
      dataIndex: 'movementDate',
      title: 'Movement Date',
      sorter: true,
      render(value) {
        return value ? dayjs(value).format('DD-MMM-YYYY') : '-'
      },
    },
    { dataIndex: ['submitedBy', 'name'], title: 'Submited By', sorter: true },
    {
      dataIndex: 'status',
      title: 'Status',
      sorter: true,
      render: (value) => {
        let color = ''
        let text = ''
        switch (value) {
          case 'pending':
            color = 'warning'
            text = 'Pending'
            break
          case 'approved':
            color = 'success'
            text = 'Approved'
            break
          case 'rejected':
            color = 'error'
            text = 'Rejected'
            break

          default:
            break
        }
        return <Tag color={color}>{text}</Tag>
      },
    },
    {
      dataIndex: 'id',
      title: 'Action',
      render(value, record) {
        return (
          <Space>
            <Button
              type="primary"
              size="middle"
              icon={<RemoveRedEyeIcon width={20} height={20} />}
              onClick={() => navigate(`/asset-insight/mutation/${value}`)}
            />
            <Button
              type="primary"
              size="middle"
              danger
              disabled={record.status !== 'pending'}
              icon={<DeleteIcon width={20} height={20} />}
              onClick={() => {}}
            />
          </Space>
        )
      },
    },
  ]

  return (
    <div>
      <TitleSection
        title="Mutation"
        fontWeight={800}
        actions={[
          <Button
            type="primary"
            key="add"
            icon={<AddIcon width={20} height={20} />}
            onClick={() => navigate('/asset-insight/mutation/add-new')}
          >
            Add New Request
          </Button>,
        ]}
      />
      <Flex vertical gap={24}>
        <FilterTableSimpleInput
          disabled={isFetching}
          onChange={(value) => {
            if (value) {
              searchParams.set('query', value)
            } else {
              searchParams.delete('query')
            }
            setSearchParams(searchParams)
          }}
          value={query}
        />

        <DataTable<AssetInsightMutation>
          rowSelectable={false}
          loading={isPlaceholderData || isPending}
          dataSource={data?.data || []}
          columns={columns}
          total={data?.total}
        />
      </Flex>
    </div>
  )
}

export default AssetInsightMutationPage

import { Button, Typography } from 'antd'
import React, { CSSProperties } from 'react'
import { Link } from 'react-router-dom'
import { cn } from '@/lib/utils'
import ArrowBackIcon from '@/assets/icons/arrow-back-icon'
import styles from './title-section.module.css'

const { Title } = Typography
function TitleSection({
  title = '',
  backUrl = '',
  actions = [],
  style = {},
  className = '',
  onClickBack = undefined,
  renderTitle = undefined,
  fontWeight = 700,
}: {
  title?: string
  backUrl?: string
  onClickBack?: () => void
  actions?: Array<React.ReactNode>
  style?: CSSProperties | undefined
  className?: string
  renderTitle?: React.ReactNode
  fontWeight?: number
}) {
  return (
    <div className={cn(styles.Container, className)} style={style}>
      {backUrl && (
        <Link to={backUrl}>
          <Button
            type="text"
            size="small"
            style={{ background: '#F8F9FC' }}
            icon={<ArrowBackIcon width={24} height={24} />}
          />
        </Link>
      )}
      {!backUrl && onClickBack && (
        <Button
          type="text"
          size="small"
          style={{ background: '#F8F9FC' }}
          icon={<ArrowBackIcon width={24} height={24} />}
          onClick={onClickBack}
        />
      )}
      {renderTitle || (
        <Title level={4} className={styles.Title} style={{ fontWeight }}>
          {title}
        </Title>
      )}
      {actions && actions.length > 0 ? (
        <div className={styles.Actions}>{actions.map((action) => action)}</div>
      ) : null}
    </div>
  )
}

export default TitleSection
